var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflmac drag",attrs:{"id":"ZQTC"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_ZQT_C")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("主汽温度控制模型")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"leftmain-top"},[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_1A_PVL', 'MKG', 'ZQT_1A_PVL_BM','屏过A温度过程滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.ZQT_1A_PVL,'ZQT_1A_PVL', 'MKG', 'ZQT_1A_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1A_PVL)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_1A_TC,
                    'ZQT_1A_TC',
                     'ZQT_1A_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1A_TC)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SPR")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'SP', 'XK__p__ZQT_XK1', 'ZQT_XK1_SP_BM','一级甲主汽温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.XK__p__ZQT_XK1.SP,'SP', 'XK__p__ZQT_XK1', 'ZQT_XK1_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.XK__p__ZQT_XK1.SP))])]),_c('div',{staticClass:"zlbutton",on:{"click":function($event){return _vm.toCompon(13,'ZQT1ASP','负荷气温补偿折现','风量','FM')}}},[_vm._v("SP增量")]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_1A_SP', 'IOG', 'ZQT_1A_SP_BM','甲一级减温控制点')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.ZQT_1A_SP,'ZQT_1A_SP', 'IOG', 'ZQT_XK1_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_1A_SP))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_1A_SP_HL,
                    'ZQT_1A_SPH',
                     'ZQT_1A_SP_HL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1A_SP_HL)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_1A_SP_LL,
                    'ZQT_1A_SPL',
                     'ZQT_1A_SP_LL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1A_SP_LL)+" ")])])]),_c('div',{staticClass:"left-span2 fl"},[_c('div',{staticClass:"lablebt1"},[_vm._v(" 左一级减温控制 ")]),_c('div',{staticClass:"left-span2-row1"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK1.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK1
              ? _vm.toDetail(
                  1,
                  'ZQT_XK1',
                  'XK__p__ZQT_XK1',
                  '',
                  '一级甲主汽温度'
                )
              : ''}}},[_vm._v("X")]),_c('div',{staticClass:"bottonx fr",style:({
              background:
              _vm.infoList.RQM__p__ZQT_MAN1.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN2.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN3.RM == 1 || _vm.infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }),on:{"click":function($event){return _vm.toCompon(0,'MFL_MANQW',4)}}},[_vm._v("A")])]),_c('div',{staticClass:"left-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.RQM__p__ZQT_QK1.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK1
              ? _vm.toDetail(
                  2,
                  'ZQT_QK1',
                  'RQM__p__ZQT_QK1',
                  '',
                  '一级减温左前馈'
                )
              : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
            background: !_vm.infoList.MKG.ZQT_1A_QS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){return _vm.toIpt(_vm.infoList.MKG.ZQT_1A_QS, 'ZQT_1A_QS','ZQT_1A_QS','MKG')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1A_QS?'串级':'单回路'))])]),_c('div',{staticClass:"left-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK2.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK2
              ? _vm.toDetail(
                  1,
                  'ZQT_XK2',
                  'XK__p__ZQT_XK2',
                  '',
                  '一级甲主汽温度副调'
                )
              : ''}}},[_vm._v("X")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN1")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'RQM__p__ZQT_MAN1', 'ZQT_MAN1_AV_BM','一级甲减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.RQM__p__ZQT_MAN1.AV,'AV', 'RQM__p__ZQT_MAN1', 'ZQT_MAN1_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN1.AV))])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1A_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN1.OutT,
                    'ZQT_MV1A_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN1'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN1.OutT)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1A_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN1.OutB,
                    'ZQT_1A_SP_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN1'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN1.OutB)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'JWHT_1A_PVL', 'MKG', 'JWHT_1A_PVL_BM','一级左减温后温度滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.JWHT_1A_PVL,'JWHT_1A_PVL', 'MKG', 'JWHT_1A_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_1A_PVL))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.JWHT_1A_TC,
                    'JWHT_1A_TC',
                     'JWHT_1A_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_1A_TC)+" ")])])])]),_c('div',[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_1B_PVL', 'MKG', 'ZQT_1B_PVL_BM','屏过B温度过程滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.ZQT_1B_PVL,'ZQT_1B_PVL', 'MKG', 'ZQT_1B_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1B_PVL)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_1B_TC,
                    'ZQT_1B_TC',
                     'ZQT_1B_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1B_TC)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SPR")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'SP', 'XK__p__ZQT_XK3', 'ZQT_XK3_SP_BM','一级乙主汽温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.XK__p__ZQT_XK3.SP,'SP', 'XK__p__ZQT_XK3', 'ZQT_XK3_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.XK__p__ZQT_XK3.SP))])]),_c('div',{staticClass:"zlbutton",on:{"click":function($event){return _vm.toCompon(13,'ZQT1BSP','负荷气温补偿折现','风量','FM')}}},[_vm._v("SP增量")]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_1B_SP', 'IOG', 'ZQT_1B_SP_BM','乙一级减温控制点')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.ZQT_1B_SP,'ZQT_1B_SP', 'IOG', 'ZQT_1B_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_1B_SP))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_1B_SP_HL,
                    'ZQT_1B_SPH',
                     'ZQT_1B_SP_HL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1B_SP_HL)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_1B_SP_LL,
                    'ZQT_1B_SPL',
                     'ZQT_1B_SP_LL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_1B_SP_LL)+" ")])])]),_c('div',{staticClass:"left-span2 fl"},[_c('div',{staticClass:"lablebt1"},[_vm._v(" 右一级减温控制 ")]),_c('div',{staticClass:"left-span2-row1 lablebt1"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK3.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK3
              ? _vm.toDetail(
                  1,
                  'ZQT_XK3',
                  'XK__p__ZQT_XK3',
                  '',
                  '一级乙主汽温度'
                )
              : ''}}},[_vm._v("X")]),_c('div',{staticClass:"bottonx fr",style:({
              background:
              _vm.infoList.RQM__p__ZQT_MAN1.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN2.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN3.RM == 1 || _vm.infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }),on:{"click":function($event){return _vm.toCompon(0,'MFL_MANQW',4)}}},[_vm._v("A")])]),_c('div',{staticClass:"left-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.RQM__p__ZQT_QK2.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK2
              ? _vm.toDetail(
                  2,
                  'ZQT_QK2',
                  'RQM__p__ZQT_QK2',
                  '',
                  '一级减温右前馈'
                )
              : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
            background: !_vm.infoList.MKG.ZQT_1B_QS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){return _vm.toIpt(_vm.infoList.MKG.ZQT_1B_QS, '此按钮','ZQT_1B_QS','MKG')}}},[_vm._v(_vm._s(_vm.infoList.MKG.ZQT_1B_QS?'串级':'单回路')+" ")])]),_c('div',{staticClass:"left-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK4.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK4
              ? _vm.toDetail(
                  1,
                  'ZQT_XK4',
                  'XK__p__ZQT_XK4',
                  '',
                  '一级主汽温度副调'
                )
              : ''}}},[_vm._v("X")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN2")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'RQM__p__ZQT_MAN2', 'ZQT_MAN2_AV_BM','一级乙减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.RQM__p__ZQT_MAN2.AV,'AV', 'RQM__p__ZQT_MAN2', 'ZQT_MAN2_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN2.AV))])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1B_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN2.OutT,
                    'ZQT_MV1B_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN2'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN2.OutT)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1B_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN2.OutB,
                    'ZQT_MV1B_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN2'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN2.OutB)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'JWHT_1B_PVL', 'MKG', 'JWHT_1B_PVL_BM','一级右减温后温度滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.JWHT_1B_PVL,'JWHT_1B_PVL', 'MKG', 'JWHT_1B_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_1B_PVL))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.JWHT_1A_TC,
                    'JWHT_1B_TC',
                     'JWHT_1B_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_1B_TC)+" ")])])])])]),_c('div',{staticClass:"rightmain fl"},[_c('div',{staticClass:"rightmain-top"},[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_2A_SP_HL,
                    'ZQT_2A_SPH',
                     'ZQT_2A_SP_HL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2A_SP_HL)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_2A_SP_LL,
                    'ZQT_2A_SPL',
                     'ZQT_2A_SP_LL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2A_SP_LL)+" ")])]),_c('div',{staticClass:"left-span-col1 "},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_2A_SP', 'IOG', 'ZQT_2A_SP_BM','甲二级减温控制点')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.ZQT_2A_SP,'ZQT_2A_SP', 'IOG', 'ZQT_2A_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2A_SP))])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_2A_PVL', 'MKG', 'ZQT_2A_PVL_BM','主汽温度过程滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.ZQT_2A_PVL,'ZQT_2A_PVL', 'MKG', 'ZQT_2A_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2A_PVL))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_2A_TC,
                    'ZQT_2A_TC',
                     'ZQT_2A_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2A_TC)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'JWHT_2A_PVL', 'MKG', 'JWHT_2A_PVL_BM','二级左减温后温度滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.JWHT_2A_PVL,'JWHT_2A_PVL', 'MKG', 'JWHT_2A_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_2A_PVL))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.JWHT_2A_TC,
                    'JWHT_2A_TC',
                     'JWHT_2A_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_2A_TC)+" ")])])]),_c('div',{staticClass:"right-span2 fl"},[_c('div',{staticClass:"lablebt"},[_vm._v(" 左二级减温控制 ")]),_c('div',{staticClass:"right-span2-row1"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK5.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK5
              ? _vm.toDetail(
                  1,
                  'ZQT_XK5',
                  'XK__p__ZQT_XK5',
                  '',
                  '二级甲减温先控调节'
                )
              : ''}}},[_vm._v("X")])]),_c('div',{staticClass:"right-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.RQM__p__ZQT_QK3.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK3
              ? _vm.toDetail(
                  2,
                  'ZQT_QK3',
                  'RQM__p__ZQT_QK3',
                  '',
                  '二级减温左前馈'
                )
              : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
            background: !_vm.infoList.MKG.ZQT_2A_QS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){return _vm.toIpt(_vm.infoList.MKG.ZQT_2A_QS, 'ZQT_2A_QS','ZQT_2A_QS','MKG')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2A_QS?'串级':'单回路'))])]),_c('div',{staticClass:"right-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK6.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK6
              ? _vm.toDetail(
                  1,
                  'ZQT_XK6',
                  'XK__p__ZQT_XK6',
                  '',
                  '二级甲减温先控副调节'
                )
              : ''}}},[_vm._v("X")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"20vw"},style:({
            background: !_vm.infoList.RQM__p__ZQT_QK5.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK5
              ? _vm.toDetail(
                  2,
                  'ZQT_QK5',
                  'RQM__p__ZQT_QK5',
                  '',
                  '负荷前馈'
                )
              : ''}}},[_vm._v(" Q ")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",staticStyle:{"margin-right":"1vw"},style:({
              background:
              _vm.infoList.RQM__p__ZQT_MAN1.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN2.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN3.RM == 1 || _vm.infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }),on:{"click":function($event){return _vm.toCompon(0,'MFL_MANQW',4)}}},[_vm._v("A")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN3")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'RQM__p__ZQT_MAN3', 'ZQT_MAN3_AV_BM','二级甲减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.RQM__p__ZQT_MAN3.AV,'AV', 'RQM__p__ZQT_MAN3', 'ZQT_MAN3_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN3.AV))])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2A_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN3.OutT,
                    'ZQT_MV2A_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN3'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN3.OutT)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2A_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN3.OutB,
                    'ZQT_MV2A_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN3'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN3.OutB)+" ")])])])])])]),_c('div',[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_2B_SP_HL,
                    'ZQT_2B_SPH',
                     'ZQT_2B_SP_HL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2B_SP_HL)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_2B_SP_LL,
                    'ZQT_2B_SPL',
                     'ZQT_2B_SP_LL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2B_SP_LL)+" ")])]),_c('div',{staticClass:"left-span-col1 "},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_2B_SP', 'IOG', 'ZQT_2B_SP_BM','乙一级减温控制点')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.ZQT_2B_SP,'ZQT_2B_SP', 'IOG', 'ZQT_2B_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.ZQT_2B_SP))])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'ZQT_2B_PVL', 'MKG', 'ZQT_2B_PVL_BM','主汽温度过程滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.ZQT_2B_PVL,'ZQT_2B_PVL', 'MKG', 'ZQT_2B_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2B_PVL))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQT_2B_TC,
                    'ZQT_2B_TC',
                     'ZQT_2B_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQT_2B_TC)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'JWHT_2B_PVL', 'MKG', 'JWHT_2B_PVL_BM','二级右减温后温度滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.JWHT_2B_PVL,'JWHT_2B_PVL', 'MKG', 'JWHT_2B_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_2B_PVL))])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.JWHT_2B_TC,
                    'JWHT_2B_TC',
                     'JWHT_2B_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.JWHT_2B_TC)+" ")])])]),_c('div',{staticClass:"right-span2 fl"},[_c('div',{staticClass:"lablebt"},[_vm._v(" 右二级减温控制 ")]),_c('div',{staticClass:"right-span2-row1",staticStyle:{"margin-top":"2vh"}},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK7.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK7
              ? _vm.toDetail(
                  1,
                  'ZQT_XK7',
                  'XK__p__ZQT_XK7',
                  '',
                  '二级乙减温先控调节'
                )
              : ''}}},[_vm._v("X")])]),_c('div',{staticClass:"right-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.RQM__p__ZQT_QK4.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__ZQT_QK4
              ? _vm.toDetail(
                  2,
                  'ZQT_QK4',
                  'RQM__p__ZQT_QK4',
                  '',
                  '二级减温右前馈'
                )
              : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
            background: !_vm.infoList.MKG.ZQT_2B_QS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){return _vm.toIpt(_vm.infoList.MKG.ZQT_2B_QS, '此按钮','ZQT_2B_QS','MKG')}}},[_vm._v(_vm._s(_vm.infoList.MKG.ZQT_2B_QS?'串级':'单回路')+" ")])]),_c('div',{staticClass:"right-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__ZQT_XK8.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__ZQT_XK8
              ? _vm.toDetail(
                  1,
                  'ZQT_XK8',
                  'XK__p__ZQT_XK8',
                  '',
                  '二级乙减温先控副调节'
                )
              : ''}}},[_vm._v("X")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",staticStyle:{"margin-right":"1vw"},style:({
              background:
              _vm.infoList.RQM__p__ZQT_MAN1.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN2.RM== 1 || _vm.infoList.RQM__p__ZQT_MAN3.RM == 1 || _vm.infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }),on:{"click":function($event){return _vm.toCompon(0,'MFL_MANQW',4)}}},[_vm._v("A")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN4")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'RQM__p__ZQT_MAN4', 'ZQT_MAN4_AV_BM','二级乙减温水手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.RQM__p__ZQT_MAN4.AV,'AV', 'RQM__p__ZQT_MAN4', 'ZQT_MAN4_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN4.AV))])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2B_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN4.OutT,
                    'ZQT_MV2B_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN4'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN4.OutT)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2B_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__ZQT_MAN4.OutB,
                    'ZQT_MV2B_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN4'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__ZQT_MAN4.OutB)+" ")])])])])])])])]),_c('div'),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.MfZQFOZX)?_c('MfZQFOZX',{attrs:{"infoList":_vm.infoList,"MfZQFOZXCS1":_vm.MfZQFOZXCS1,"MfZQFOZXCS2":_vm.MfZQFOZXCS2,"MfZQFOZXCS3":_vm.MfZQFOZXCS3,"MfZQFOZXCS4":_vm.MfZQFOZXCS4},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }