<template>
	<div class="jrlmqzg drag" id="YQOC" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>
				{{ projectData.project }}-YQO-C
			</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">送风优化控制模型</div>
		<div class="main">
			<!-- 命名:按钮汉字小写  键值 第一行大写 -->
			<div class="TOP">
				<table>
					<tr>
						<td class="tt">TOP</td>
						<td class="tv1" @click="
                  toIpt(
                    infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP,
                    'TOP',
                     'TOP',
                    'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL',
                   
                  )
                ">
							{{ infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP }}
						</td>
						<td class="tt">ZL_H</td>
						<td class="tv1" @click="
                  toIpt(
                    infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H,
                    'ZL_H',
                     'YHZL_H',
                    'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL',
                   
                  )
                ">
							{{ infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H }}
						</td>
					</tr>
					<tr>
						<td class="tt">SOP</td>
						<td class="tv1" @click="
                  toIpt(
                    infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP,
                    'SOP',
                     'SOP',
                    'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL',
                   
                  )
                ">
							{{ infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP }}
						</td>

						<td class="tt">ZL_L</td>
						<td class="tv1" @click="
                  toIpt(
                    infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,
                    'ZL_L',
                     'YHZL_L',
                    'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL',
                   
                  )
                ">
							{{ infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L }}
						</td>
					</tr>
					<tr>
						<td class="tt">EOP</td>
						<td class="tv1" @click="
                  toIpt(
                    infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP,
                    'EOP',
                     'EOP',
                    'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL',
                   
                  )
                ">
							{{ infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP }}
						</td>
						<td class="tt">YHJG</td>
						<td class="tv1" @click="
                  toIpt(
                    infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG,
                    'YHJG',
                     'YHJG',
                    'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL',
                   
                  )
                ">
							{{ infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG }}
						</td>
					</tr>
					<tr>
						<td class="tt">YHZL</td>
						<td class="tv2"
							@click="toCompon(2, 'YHJGSYSJ', 'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL', 'YHJGSYSJ1_BM','优化间隔剩余时间（分钟）')"
							@dblclick="Cclick(infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ,'YHJGSYSJ', 'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL', 'YHJGSYSJ1_BM')">
							{{infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ}}
						</td>
						<td class="tt">SYSJ</td>
						<td class="tv2"
							@click="toCompon(2, 'YHJGSYSJ', 'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL', 'YHJGSYSJ1_BM','优化间隔剩余时间（分钟）')"
							@dblclick="Cclick(infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ,'YHJGSYSJ', 'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL', 'YHJGSYSJ1_BM')">
							{{infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ}}
						</td>
					</tr>
					<tr>
						<td class="tt">ZT</td>
						<td class="tv2"
							@click="toCompon(2, 'YHZT', 'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL', 'YHZT1_BM','优化状态')"
							@dblclick="Cclick(infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZT,'YHZT', 'YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL', 'YHZT1_BM')">
							{{infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZT}}
						</td>
						<!-- 此处需要进度条组件 不再完善 -->
						<td class="tt" colspan="2">
							<div class="jindutiao"></div>
						</td>
					</tr>
					<tr>
						<td class="tt" colspan="2"></td>
						<td class="tv2 jl">0</td>
						<td class="tv2 jr"> 100 &nbsp;</td>
					</tr>

				</table>
			</div>
			<div class="yhqc"
				@click="toIpt(infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__.BCSYHQY, '优化状态','BCSYHQY','YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__')">
				{{infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__.BCSYHQY?'优化投用':'优化切除'}}
			</div>
			<div class="qzyh"
				@click="toIpt(infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.RESET1, '此按钮','RESET1','YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
				{{infoList.YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.RESET1?'强制优化':'切除强制'}}

			</div>
			<div class="YQO_0SP">
				<div class="row">
					<div class="col1 fl">YQO_0SP</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_OSP', 'MKG', 'YQO_OSP_BM','氧量优化设定值增量')"
						@dblclick="Cclick(infoList.MKG.YQO_OSP,'YQO_OSP', 'MKG', 'YQO_OSP_BM')">{{infoList.MKG.YQO_OSP}}
					</div>
				</div>
			</div>
			<div class="YQO_SP_HL">
				<div class="row">
					<div class="col1 fl">YQO_SP_HL</div>
					<div class="col3 fl  " @click="
                  toIpt(
                    infoList.MKG.YQO_SP_HL,
                    'YQO_SP_HL',
                     'YQO_SP_HL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.YQO_SP_HL }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_SP_LL</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.YQO_SP_LL,
                    'YQO_SP_LL',
                     'YQO_SP_LL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.YQO_SP_LL }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_SP_TC</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.YQO_SP_TC,
                    'YQO_SP_TC',
                     'YQO_SP_TC',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.YQO_SP_TC }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_DSP</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_DSP', 'IOG', 'YQO_DSP_BM','烟气氧量DCS设定值')"
						@dblclick="Cclick(infoList.IOG.YQO_DSP,'YQO_DSP', 'IOG', 'YQO_DSP_BM')">{{infoList.IOG.YQO_DSP}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_SP</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_SP', 'IOG', 'YQO_SP_BM','烟气氧量设定值')"
						@dblclick="Cclick(infoList.IOG.YQO_SP,'YQO_SP', 'IOG', 'YQO_SP_BM')">{{infoList.IOG.YQO_SP}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_SPB_HL</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_SPB_HL', 'MKG', 'YQO_SPB_HL_BM','烟气氧量偏置上限')"
						@dblclick="Cclick(infoList.MKG.YQO_SPB_HL,'YQO_SPB_HL', 'MKG', 'YQO_SPB_HL_BM')">
						{{infoList.MKG.YQO_SPB_HL}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_SPB_LL</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_SPB_LL', 'MKG', 'YQO_SPB_LL_BM','烟气氧量偏置下限')"
						@dblclick="Cclick(infoList.MKG.YQO_SPB_LL,'YQO_SPB_LL', 'MKG', 'YQO_SPB_HL_BM')">
						{{infoList.MKG.YQO_SPB_LL}}
					</div>
				</div>

			</div>
			<!-- <div class="yl_sp"  @click="toCompon(12,'A','MFL_','风量','FM')"> -->
			<div class="yl_sp" @click="toCompon(13,'ZQFO','负荷氧量折线','风量','FM')">
				氧量SP
			</div>
			<div class="qc1" @click="toIpt(infoList.IOG.YQO_SP_SEL, '此按钮','YQO_SP_SEL','IOG')">
				{{infoList.IOG.YQO_SP_SEL?'投用':'切除'}}

			</div>
			<div class="qc2" @click="toCompon(13,'ZQFS','最小风量折现','GML','SFF')">
				SFF-MIN
			</div>
			<div class="SFF_PV">
				<div class="row">
					<div class="col1 fl">SFF_PV</div>
					<div class="col2 fl" @click="toCompon(2, 'SFF_PV', 'MKG', 'SFF_PV_BM','送风量过程值')"
						@dblclick="Cclick(infoList.MKG.SFF_PV,'SFF_PV', 'MKG', 'SFF_PV_BM')">{{infoList.MKG.SFF_PV}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFF_TC</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.MKG.SFF_TC,
                    'SFF_TC',
                     'SFF_TC',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFF_TC }}
					</div>
				</div>
			</div>
			<div class="SFF_JB_HL">
				<div class="row">
					<div class="col1 fl">SFF_JB_HL</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.SFF_JB_HL,
                    'SFF_JB_HL',
                     'SFF_JB_HL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFF_JB_HL }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFF_JB_LL</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.SFF_JB_LL,
                    'SFF_JB_LL',
                     'SFF_JB_LL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFF_JB_LL }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFF_JB</div>
					<div class="col2 fl" @click="toCompon(2, 'SFF_JB2', 'MKG', 'SFF_JB_BM','送风流量基本值')"
						@dblclick="Cclick(infoList.MKG.SFF_JB2,'SFF_JB2', 'MKG', 'SFF_JB_BM')">{{infoList.MKG.SFF_JB2}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFF_SP</div>
					<div class="col2 fl" @click="toCompon(2, 'SFF_SP', 'IOG', 'SFF_SP_BM','送风流量设定值')"
						@dblclick="Cclick(infoList.IOG.SFF_SP,'SFF_SP', 'IOG', 'SFF_SP_BM')">{{infoList.IOG.SFF_SP}}
					</div>
				</div>
			</div>
			<div class="ZFL_HL">
				<div class="row">
					<div class="col1 fl">ZFL_HL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.MKG.ZFL_HL,
                    'ZFL_HL',
                     'ZFL_HL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.ZFL_HL }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">ZFL_LL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.MKG.ZFL_LL,
                    'ZFL_LL',
                     'ZFL_LL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.ZFL_LL }}
					</div>
				</div>
			</div>
			<div class="LTP_PV_HL">
				<div class="row">
					<div class="col1 fl">LTP_PV_HL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.MKG.LTP_PV_HL,
                    'LTP_PV_HL',
                     'LTP_PV_HL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.LTP_PV_HL }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">LTP_PV_LL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.MKG.LTP_PV_LL,
                    'LTP_PV_LL',
                     'LTP_PV_LL',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.LTP_PV_LL }}
					</div>
				</div>
			</div>
			<div class="YQO_MVBB_HL">
				<div class="row">
					<div class="col1 fl ">YQO_MVBB_HL</div>
					<div class="col2 fl color1" @click="
                  toIpt(
                    infoList.RQM__p__YQO_MAN2.OutT,
                    'YQO_MVBB_HL',
                     'OutT',
                    'RQM__p__YQO_MAN2',
                   
                  )
                ">
						{{ infoList.RQM__p__YQO_MAN2.OutT }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_MVBB_LL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.RQM__p__YQO_MAN2.OutB,
                    'YQO_MVBB_LL',
                     'OutB',
                    'RQM__p__YQO_MAN2',
                   
                  )
                ">
						{{ infoList.RQM__p__YQO_MAN2.OutB }}
					</div>
				</div>
			</div>
			<div class="YQO_MAN1">
				<div class="row">
					<div class="col1 fl">YQO_MAN1</div>
					<div class="col2 fl" @click="toCompon(2, 'AV', 'RQM__p__YQO_MAN1', 'YQO_MAN1_AV_BM','1#送风机变频手操器')"
						@dblclick="Cclick(infoList.RQM__p__YQO_MAN1.AV,'AV', 'RQM__p__YQO_MAN1', 'YQO_MAN1_AV_BM')">
						{{infoList.RQM__p__YQO_MAN1.AV}}
					</div>
				</div>
			</div>
			<div class="YQO_MVBA_HL">
				<div class="row">
					<div class="col1 fl">YQO_MVBA_HL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.RQM__p__YQO_MAN1.OutT,
                    'YQO_MVBA_HL',
                     'OutT',
                    'RQM__p__YQO_MAN1',
                   
                  )
                ">
						{{ infoList.RQM__p__YQO_MAN1.OutT }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_MVBA_LL</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.RQM__p__YQO_MAN1.OutB,
                    'YQO_MVBA_LL',
                     'OutB',
                    'RQM__p__YQO_MAN1',
                   
                  )
                ">
						{{ infoList.RQM__p__YQO_MAN1.OutB }}
					</div>
				</div>
			</div>
			<div class="YQO_MAN2">
				<div class="row">
					<div class="col1 fl">YQO_MAN2</div>
					<div class="col2 fl" @click="toCompon(2, 'AV', 'RQM__p__YQO_MAN2', 'YQO_MAN2_AV_BM','2#送风机变频手操器')"
						@dblclick="Cclick(infoList.RQM__p__YQO_MAN2.AV,'AV', 'RQM__p__YQO_MAN2', 'YQO_MAN2_AV_BM')">
						{{infoList.RQM__p__YQO_MAN2.AV}}
					</div>
				</div>
			</div>
			<div class="TQ0_TC">
				<div class="row">
					<div class="col1b fl" :style="{
              background:infoList.MKG.YQO_SEL==1 ? '#2AFC30': 'red',
           
      }" @click="toIpt('1', '氧量1按钮','YQO_SEL','MKG','true')">氧量1</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_A_PV', 'IOG', 'YQO_A_PV_BM','烟气氧量过程值1')"
						@dblclick="Cclick(infoList.IOG.YQO_A_PV,'YQO_A_PV', 'IOG', 'YQO_A_PV_BM')">
						{{infoList.IOG.YQO_A_PV}}
					</div>
				</div>
				<div class="row">
					<div class="col1b fl" :style="{
              background:infoList.MKG.YQO_SEL==2 ?'#2AFC30': 'red',
      }" @click="toIpt('2', '氧量2按钮','YQO_SEL','MKG','true')">氧量2</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_B_PV', 'IOG', 'YQO_B_PV_BM','烟气氧量过程值2')"
						@dblclick="Cclick(infoList.IOG.YQO_B_PV,'YQO_B_PV', 'IOG', 'YQO_B_PV_BM')">
						{{infoList.IOG.YQO_B_PV}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_TC</div>
					<div class="col2 fl" @click="
                  toIpt(
                    infoList.MKG.YQO_TC,
                    'YQO_TC',
                     'YQO_TC',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.YQO_TC }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">YQO_PV</div>
					<div class="col2 fl" @click="toCompon(2, 'YQO_PV', 'MKG', 'YQO_PV_BM','氧量过程值')"
						@dblclick="Cclick(infoList.MKG.YQO_PV,'YQO_PV', 'MKG', 'YQO_PV_BM')">{{infoList.MKG.YQO_PV}}
					</div>
				</div>
			</div>
			<div class="SFRCLK1">
				<div class="rowb">
					实际测量
				</div>
				<div class="row">
					<div class="col1 fl">SFRCLK1</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.SFRCLK1,
                    'SFRCLK1',
                     'SFRCLK1',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFRCLK1 }}
					</div>
					<div class="col1 fl" style="margin-left:1.5vw;">SFRCLK2</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.SFRCLK2,
                    'SFRCLK2',
                     'SFRCLK2',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFRCLK2 }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFRCLC1</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.SFRCLC1,
                    'SFRCLC1',
                     'SFRCLC1',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFRCLC1 }}
					</div>
					<div class="col1 fl" style="margin-left:1.5vw;">SFRCLC2</div>
					<div class="col3 fl" @click="
                  toIpt(
                    infoList.MKG.SFRCLC2,
                    'SFRCLC2',
                     'SFRCLC2',
                    'MKG',
                   
                  )
                ">
						{{ infoList.MKG.SFRCLC2 }}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFF_A_PV</div>
					<div class="col2 fl" @click="toCompon(2, 'SFF_A_PV', 'IOG', 'SFF_A_PV_BM','送风流量过程值1')"
						@dblclick="Cclick(infoList.IOG.SFF_A_PV,'SFF_A_PV', 'IOG', 'SFF_A_PV_BM')">
						{{infoList.IOG.SFF_A_PV}}
					</div>
					<div class="col1 fl" style="margin-left:2.5vw;">SFF_B_PV</div>
					<div class="col2 fl" @click="toCompon(2, 'SFF_B_PV', 'IOG', 'SFF_B_PV_BM','送风流量过程值2')"
						@dblclick="Cclick(infoList.IOG.SFF_B_PV,'SFF_B_PV', 'IOG', 'SFF_B_PV_BM')">
						{{infoList.IOG.SFF_B_PV}}
					</div>
				</div>
				<div class="row">
					<div class="col1 fl">SFRCL1</div>
					<div class="col2 fl" @click="toCompon(2, 'SFRCL1', 'MKG', 'SFRCL1_BM','送风软测量输出1')"
						@dblclick="Cclick(infoList.MKG.SFRCL1,'SFRCL1', 'MKG', 'SFRCL1_BM')">{{infoList.MKG.SFRCL1}}
					</div>
					<div class="col1 fl" style="margin-left:2vw;">SFRCL2</div>
					<div class="col2 fl" @click="toCompon(2, 'SFRCL2', 'MKG', 'SFRCL2_BM','送风软测量输出2')"
						@dblclick="Cclick(infoList.MKG.SFRCL2,'SFRCL2', 'MKG', 'SFRCL2_BM')">{{infoList.MKG.SFRCL2}}
					</div>
				</div>
			</div>
			<div class="bottonx fl x_1" :style="{
              background:infoList.XK__p__YQO_XK1==1 || infoList.XK__p__YQO_XK1==2? '#2AFC30': 'red',
      }" @click="infoList ? toDetail(1,'YQO_XK1','XK__p__YQO_XK1','','氧量先控调节'): ''">
				X
			</div>
			<div class="bottonx fl x_2" :style="{
              background:infoList.XK__p__YQO_XK2==1 || infoList.XK__p__YQO_XK2==2? '#2AFC30': 'red',
      }" @click="infoList ? toDetail(1,'YQO_XK2','XK__p__YQO_XK2','','送风变频先控调节'): ''">
				X
			</div>
			<div class="bottonx fl a_1" :style="{
                      background:
                      infoList.RQM__p__YQO_MAN1.RM==1  || infoList.RQM__p__YQO_MAN2.RM==1  ? '#2AFC30' : 'red'
                      }" @click="toCompon(0,'MFL_MANSF',2)">
				A
			</div>
			<div class="bottonx fl r_1" :style="{
              background:infoList.RQM__p__YQO_RSF1==1 || infoList.RQM__p__YQO_RSF1==2? '#2AFC30': 'red',
      }" @click="infoList ? toDetail(3,'YQO_RSF1','RQM__p__YQO_RSF1','','送风机电流纠偏'): ''">
				R
			</div>
			<div class="PZ1">
				PZ1
			</div>
			<div class="PZ2">
				PZ2
			</div>
			<div class="SADD">
				SADD
			</div>
			<div class="SSUB">
				SSUB
			</div>
			<div class="SFJI_JPPC">
				<div class="k fl">SFJI_JPPC</div>
				<div class="v fl" @click="
                  toIpt(
                    infoList.MKG.SFJI_JPPC,
                    'SFJI_JPPC',
                     'SFJI_JPPC',
                    'MKG',
                   
                  )
                ">
					{{ infoList.MKG.SFJI_JPPC }}
				</div>

			</div>
			<!-- 背景图div -->
		</div>

		<mflmanyManual v-if="mflmanyManual" :titname="Manualtitname" :numM="Manualnode" :mark="Manualmark"
			:manvar="Manualvar" @sendStatus="isClose" :infoList="infoList"></mflmanyManual>
		<MfFMAZX v-if="MfFMAZX" @sendStatus='isClose' :infoList="infoList" :MfFMAZXCS1="MfFMAZXCS1"
			:MfFMAZXCS2="MfFMAZXCS2" :MfFMAZXCS3="MfFMAZXCS3" :MfFMAZXCS4="MfFMAZXCS4"></MfFMAZX>
		<MfZQFOZX v-if="MfZQFOZX" @sendStatus='isClose' :infoList="infoList" :MfZQFOZXCS1="MfZQFOZXCS1"
			:MfZQFOZXCS2="MfZQFOZXCS2" :MfZQFOZXCS3="MfZQFOZXCS3" :MfZQFOZXCS4="MfZQFOZXCS4"></MfZQFOZX>

		 <Historical v-if="isHshow" @sendStatus="isClose" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
	</div>
</template>


<script>
	import inputVal from "@/components/inputVal.vue" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import mflmanyManual from "@/components/MflmanyManual.vue"; //多手操器
	import MfZQFOZX from "@/views/MfBoiler/MFZQFO_ZX/index.vue";
	import MfFMAZX from "@/views/MfBoiler/MFFMA_ZX/index.vue";
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			MfZQFOZX,
			MfFMAZX,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			mflmanyManual
		},
		data: () => {
			return {
				chName: '',
				mflmanyManual: false,
				Manualmark: '',
				Manualvar: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
				MfZQFOZXCS1: "",
				MfZQFOZXCS2: "",
				MfZQFOZXCS3: "",
				MfZQFOZXCS4: "",
				MfZQFOZX: "",
				MfFMAZX: "",
				MfFMAZXCS1: "",
				MfFMAZXCS2: "",
				MfFMAZXCS3: "",
				MfFMAZXCS4: "",

			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'mfkfirstcontol'
							break
						case 3:
							pathname = 'mfRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'isPshow04', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'MfZQFOZX':
						return this.MfZQFOZX = val1
					case 'mflmanyManual':
						return this.mflmanyManual = val1
				}
			},
			toIpt(data, name, historyname, node, statue) {

				this.$refs.inputVal.open(data, name, historyname, node, statue)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.mflmanyManual = true, this.Manualtitname = name, this.Manualnode = name2,
								this.Manualmark = name3, this.Manualvar = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 13:
							return this.MfZQFOZX = true, this.MfZQFOZXCS1 = name, this.MfZQFOZXCS2 = name2, this
								.MfZQFOZXCS3 = name3, this.MfZQFOZXCS4 = titname
						case 12:
							return this.MfFMAZX = true, this.MfFMAZXCS1 = name, this.MfFMAZXCS2 = name2, this
								.MfFMAZXCS3 = name3, this.MfFMAZXCS4 = titname
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>


<style lang="scss" scoped>
	.jrlmqzg {
		width: 56vw;
		height: 78vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;
		color: #ffffff;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main {
			cursor: pointer;
			width: 34vw;
			height: 44vh;
			//  background: #e9f7ff;
			background-image: url("~@/assets/images/MFL_送风优化切图.png");
			background-size: 100% 100%;
			//  cursor:move;
			border-radius: 5px;
			color: #ffffff;
			margin-left: 12vw;
			margin-top: 9vh;
		}

		.TOP {
			width: 11vw;
			height: 14vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d60;
			position: absolute;
			left: 1vw;
			top: 17vh;

			table {
				width: 11vw;
				height: 14vh;
				table-layout: fixed;
				padding: 0px;
				display: inline-block;

				tr {
					width: 11vw;

					td {
						height: 2vh;
						text-align: center;
					}
				}

				.tt {
					font-size: 0.9vw;
					line-height: 2vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					width: 2.5vw;

				}

				.tv1 {
					cursor: pointer;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					width: 3vw;
					cursor: pointer;
				}

				.tv2 {
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
				}

				.jindutiao {
					width: 8vh;
					height: 1vh;
					background-color: #00ffb4;
					margin: 0.5vh 0vw;
				}

				.jl {
					text-align: left;
				}

				.jr {
					text-align: right;
				}
			}
		}

		.yhqc {
			width: 4.6vw;
			height: 3vh;
			background-color: #22fff760;
			border-radius: 0vw;
			border: solid 0vw #0bd0c960;
			font-family: PingFang-SC-Regular;
			font-size: 0.9vw;
			;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #22fff7;
			text-align: center;
			line-height: 3vh;
			position: absolute;
			left: 12.3vw;
			top: 20.5vh;
		}

		.qzyh {
			width: 4.6vw;
			height: 3vh;
			background-color: #04ff57;
			border-radius: 0vw;
			border: solid 0vw #0bd0c960;
			font-family: PingFang-SC-Regular;
			font-size: 0.9vw;
			;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0a4c62;
			text-align: center;
			line-height: 3vh;
			position: absolute;
			left: 12.3vw;
			top: 24.5vh;
		}

		.SADD {
			width: 4.6vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.2vh;
			letter-spacing: 0vh;
			color: #2fc3e3;
			text-align: center;
			line-height: 3vh;
			position: absolute;
			left: 28vw;
			top: 64.3vh;
		}

		.SSUB {
			width: 4.6vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.2vh;
			letter-spacing: 0vh;
			color: #2fc3e3;
			text-align: center;
			line-height: 3vh;
			position: absolute;
			left: 28vw;
			top: 66.8vh;
		}

		.PZ1 {
			width: 4.6vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.2vh;
			letter-spacing: 0vh;
			color: #2fc3e3;
			text-align: center;
			line-height: 3vh;
			position: absolute;
			left: 37.4vw;
			top: 22.3vh;
		}

		.PZ2 {
			width: 4.6vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 15px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.2vh;
			letter-spacing: 0vh;
			color: #2fc3e3;
			text-align: center;
			line-height: 3vh;
			position: absolute;
			left: 37.4vw;
			top: 60.3vh;
		}

		.SFJI_JPPC {
			width: 10vw;
			height: 2.6vh;
			line-height: 2.6vh;

			position: absolute;
			left: 46.1vw;
			top: 17.2vh;

			.k {
				width: 6.6vw;
				font-family: PingFang-SC-Regular;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2.2vh;
				letter-spacing: 0vh;
				color: #8aeaff;
			}

			.v {
				color: #00ffb4;
			}
		}

		.YQO_0SP {
			width: 7.3vw;
			height: 2.6vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 17.3vw;
			top: 23vh;

			.row {
				width: 7.3vw;
				height: 2.6vh;

				.col1 {
					width: 4vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
					cursor: pointer;
				}
			}
		}

		.YQO_SP_HL {
			width: 8.6vw;
			height: 18.2vh;
			background-color: #1a3949;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 18.8vw;
			top: 41vh;

			.row {
				width: 8.6vw;
				height: 2.6vh;

				.col1 {
					width: 5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
					cursor: pointer;
				}

				.col3 {
					width: 3.5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
					cursor: pointer;
				}
			}
		}

		.YQO_MAN1 {
			width: 9.3vw;
			height: 2.6vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 46.1vw;
			top: 22.6vh;

			.row {
				width: 9.3vw;
				height: 2.6vh;

				.col1 {
					width: 5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 4.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}
			}
		}

		.YQO_MAN2 {
			width: 9.3vw;
			height: 2.6vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 46.1vw;
			top: 60.3vh;

			.row {
				width: 9.3vw;
				height: 2.6vh;

				.col1 {
					width: 5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 4.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}
			}
		}

		.color1 {
			color: #22fff7
		}

		.yl_sp {
			position: absolute;
			left: 19.1vw;
			top: 28.4vh;
			width: 3.8vw;
			height: 2.6vh;
			background-color: #22fff7;
			border-radius: 0.1vw;
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 0.9vw;
			;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.6vh;
			letter-spacing: 0vw;
			color: #0a4c62;
		}

		.qc1 {
			position: absolute;
			left: 12.5vw;
			top: 41vh;
			width: 3.8vw;
			height: 2.6vh;
			background-color: #22fff7;
			border-radius: 0.1vw;
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 0.9vw;
			;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.6vh;
			letter-spacing: 0vw;
			color: #0a4c62;
		}

		.qc2 {
			position: absolute;
			left: 31vw;
			top: 46vh;
			width: 3.8vw;
			height: 2.6vh;
			background-color: #22fff7;
			border-radius: 0.1vw;
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 0.9vw;
			;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.6vh;
			letter-spacing: 0vw;
			color: #0a4c62;
		}

		.x_1 {
			position: absolute;
			left: 21.2vw;
			top: 38vh;
		}

		.x_2 {
			position: absolute;
			left: 38.5vw;
			top: 42.2vh;
		}

		.a_1 {
			position: absolute;
			left: 43vw;
			top: 42.2vh;
		}

		.r_1 {
			position: absolute;
			left: 43vw;
			top: 17vh;
		}

		.SFF_PV {
			width: 9vw;
			height: 5.2vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 28.2vw;
			top: 23vh;

			.row {
				width: 9vw;
				height: 2.6vh;

				.col1 {
					width: 4vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}
			}
		}

		.TQ0_TC {
			width: 7.3vw;
			height: 10.4vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 4.7vw;
			top: 33.8vh;

			.row {
				width: 7.3vw;
				height: 2.6vh;

				.col1 {
					width: 4vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col1b {
					width: 3.8vw;
					height: 2.4vh;
					background-color: #22fff7;
					border-radius: 0.2vh;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.4vh;
					letter-spacing: 0vh;
					color: #0a4c62;
					text-align: center;
					margin-top: 0.2vh;
					margin-left: 0.2vw;

				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}
			}
		}

		.SFRCLK1 {
			width: 18vw;
			height: 14.8vh;
			background-color: #0d3449;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 0.3vw;
			top: 52.8vh;

			.rowb {
				width: 18vw;
				height: 2.6vh;
				text-align: center;
				line-height: 2.6vh;
				font-size: 0.9vw;
				;
				color: #2fc3e3;
			}

			.row {
				width: 18vw;
				height: 3vh;

				.col1 {
					width: 4.2vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 2vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}

				.col3 {
					width: 2.8vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
				}
			}
		}

		.ZFL_HL {
			width: 7.3vw;
			height: 5.2vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 28vw;
			top: 50vh;

			.row {
				width: 7.3vw;
				height: 2.6vh;

				.col1 {
					width: 4vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
				}
			}
		}

		.LTP_PV_HL {
			width: 8.3vw;
			height: 5.2vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 32.3vw;
			top: 64.4vh;

			.row {
				width: 8.3vw;
				height: 2.6vh;

				.col1 {
					width: 5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
				}
			}
		}

		.YQO_MVBB_HL {
			width: 10.3vw;
			height: 5.2vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 41.3vw;
			top: 64.4vh;

			.row {
				width: 10.3vw;
				height: 2.6vh;

				.col1 {
					width: 6vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
				}
			}
		}

		.YQO_MVBA_HL {
			width: 11vw;
			height: 5.2vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 44.5vw;
			top: 25.7vh;

			.row {
				width: 11vw;
				height: 2.6vh;

				.col1 {
					width: 6.5vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 4.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
				}
			}
		}

		.SFF_JB_HL {
			width: 7.3vw;
			height: 10.4vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			position: absolute;
			left: 30vw;
			top: 29.5vh;

			.row {
				width: 7.3vw;
				height: 2.6vh;

				.col1 {
					width: 4vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.col2 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}

				.col3 {
					width: 3.3vw;
					font-family: PingFang-SC-Regular;
					font-size: 0.9vw;
					;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #00ffb4;
					text-align: center;
				}
			}
		}

		// 按钮样式
		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 1.7vh;
			// margin-right: 0.1vw;
			// color: #000000;
			cursor: pointer;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 1.8vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 2.5vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 53vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
			margin-bottom: 2vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 53vw;
		}

		.button-top {
			width: 5vw;
			height: 3vh;
			background-color: #008db1;
			text-align: center;
			line-height: 3vh;
			margin-right: 3vw;
		}

		.color1 {
			color: #00ffb4;
		}



	}
</style>
