var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflfmazx drag",attrs:{"id":"mflfmazx"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_"+_vm._s(_vm.MfFMAZXCS4)+_vm._s(_vm.MfFMAZXCS1)+"_ZX")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v(_vm._s(_vm.MfFMAZXCS1)+"磨"+_vm._s(_vm.MfFMAZXCS3)+"折线参数")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 序号")]),_c('div',{staticClass:"row1-span2  fl",staticStyle:{"color":"#8aeaff"}},[_vm._v(" "+_vm._s(_vm.MfFMAZXCS1)+"MSPJ_IN")]),_c('div',{staticClass:"row1-span3  fl",staticStyle:{"color":"#8aeaff"}},[_vm._v(" "+_vm._s(_vm.MfFMAZXCS1)+"MSPJ_AV")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 1")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__1__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__1__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__1__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__1__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 2")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__2__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__2__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__2__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__2__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 3")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__3__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__3__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__3__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__3__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 4")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__4__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__4__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__4__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__4__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 5")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__5__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__5__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__5__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__5__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 6")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__6__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__6__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__6__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__6__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 7")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__7__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__7__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__7__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__7__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 8")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__8__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__8__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__8__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__8__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 9")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__9__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__9__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__9__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__9__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 10")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__10__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__10__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__10__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__10__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 11")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__11__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].X__ll__11__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__11__rr__,
                    _vm.MfFMAZXCS1+'MSPJ_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__'+_vm.MfFMAZXCS2+ _vm.MfFMAZXCS1 +'_SPJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPJ'].Y__ll__11__rr__)+" ")])]),_c('div',{staticClass:"row3 "},[_c('div',{staticClass:"fl ",staticStyle:{"margin-left":"8vw"}},[_vm._v("IN = ")]),_c('div',{staticClass:"fl ",on:{"click":function($event){return _vm.toCompon(2,'GML_'+_vm.MfFMAZXCS1 +'_PVL', 'MKG', 'GML_'+_vm.MfFMAZXCS1 +'_PVL_BM','屏过A温度过程滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG['GML_'+_vm.MfFMAZXCS1 +'_PVL'],'GML_'+_vm.MfFMAZXCS1 +'_PVL','MKG', 'GML_'+_vm.MfFMAZXCS1 +'_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG['GML_'+_vm.MfFMAZXCS1 +'_PVL']))]),_c('div',{staticClass:"fl ",staticStyle:{"margin-left":"3vw"}},[_vm._v("AV = ")]),_c('div',{staticClass:"fl ",on:{"click":function($event){return _vm.toCompon(2,'AV', 'MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1 +'_SPJ',  _vm.MfFMAZXCS2 +_vm.MfFMAZXCS1 +'_AV_BM','屏过出口设定值输出')},"dblclick":function($event){return _vm.Cclick(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1 +'_SPJ'].AV,'AV', 'MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1 +'_SPJ',_vm.MfFMAZXCS2 +_vm.MfFMAZXCS1 +'_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1 +'_SPJ'].AV))])])]),_c('div',{staticClass:"main-bottom",staticStyle:{"margin-top":"1vh"}},[_c('div',{staticClass:"fl",staticStyle:{"margin-left":"9vw"}},[_vm._v(" "+_vm._s(_vm.MfFMAZXCS2)+_vm._s(_vm.MfFMAZXCS1)+"_SPB = ")]),_c('div',{staticClass:"fl color1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG[''+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPB2'],
                    ''+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPB',
                     ''+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPB2',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG[''+_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SPB2'])+" ")])]),_c('div',{staticClass:"main-bottom"},[_c('div',{staticClass:"fl",staticStyle:{"margin-left":"9vw"}},[_vm._v(" "+_vm._s(_vm.MfFMAZXCS2)+_vm._s(_vm.MfFMAZXCS1)+"_SP = AV + SPB = ")]),_c('div',{staticClass:"fl",on:{"click":function($event){return _vm.toCompon(2,_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SP', 'IOG', 'MFL_'+_vm.MfFMAZXCS1 +'_SP_BM', '水位设定值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG[_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SP'],_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SP', 'IOG', 'MFL_'+_vm.MfFMAZXCS1 +'_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG[_vm.MfFMAZXCS2+_vm.MfFMAZXCS1+'_SP']))])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }