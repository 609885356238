<template>
	<div class="mflmac drag" id="ZQTC" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>{{ projectData.project }}_ZQT_C</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">主汽温度控制模型</div>
		<!-- 页面划分为2个部分。左边和右边。左边包含上下两部分，右边也包含上下两部分 -->
		<div class="main">
			<!-- 左边 -->
			<div class="leftmain fl">
				<!-- 左边上半部分 -->
				<div class="leftmain-top">
					<div class="left-span1 fl">
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1A_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_1A_PVL', 'MKG', 'ZQT_1A_PVL_BM','屏过A温度过程滤波值')"
								@dblclick="Cclick(infoList.MKG.ZQT_1A_PVL,'ZQT_1A_PVL', 'MKG', 'ZQT_1A_PVL_BM')">
								{{infoList.MKG.ZQT_1A_PVL}}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1A_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_1A_TC,
                    'ZQT_1A_TC',
                     'ZQT_1A_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_1A_TC }}
							</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">ZQT_1A_SPR</div>
							<div class="valuex fl color" @click="toCompon(2, 'SP', 'XK__p__ZQT_XK1', 'ZQT_XK1_SP_BM','一级甲主汽温度')"
								@dblclick="Cclick(infoList.XK__p__ZQT_XK1.SP,'SP', 'XK__p__ZQT_XK1', 'ZQT_XK1_SP_BM')">
								{{infoList.XK__p__ZQT_XK1.SP}}</div>
						</div>
						<div class="zlbutton" @click="toCompon(13,'ZQT1ASP','负荷气温补偿折现','风量','FM')">SP增量</div>

						<div class="left-span-col1 left-jg2">
							<div class="widthd fl">ZQT_1A_SP</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_1A_SP', 'IOG', 'ZQT_1A_SP_BM','甲一级减温控制点')"
								@dblclick="Cclick(infoList.IOG.ZQT_1A_SP,'ZQT_1A_SP', 'IOG', 'ZQT_XK1_SP_BM')">
								{{infoList.IOG.ZQT_1A_SP}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1A_SPH</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_1A_SP_HL,
                    'ZQT_1A_SPH',
                     'ZQT_1A_SP_HL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_1A_SP_HL }}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1A_SPL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_1A_SP_LL,
                    'ZQT_1A_SPL',
                     'ZQT_1A_SP_LL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_1A_SP_LL }}
							</div>
						</div>
					</div>
					<div class="left-span2 fl">
						<div class="lablebt1">
							左一级减温控制
						</div>
						<!-- 第一行 X 和A -->
						<div class="left-span2-row1">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK1.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK1
              ? toDetail(
                  1,
                  'ZQT_XK1',
                  'XK__p__ZQT_XK1',
                  '',
                  '一级甲主汽温度'
                )
              : ''
          ">X</div>
							<div class="bottonx fr" :style="{
              background:
              infoList.RQM__p__ZQT_MAN1.RM== 1 || infoList.RQM__p__ZQT_MAN2.RM== 1 || infoList.RQM__p__ZQT_MAN3.RM == 1 || infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }" @click="toCompon(0,'MFL_MANQW',4)">A</div>
						</div>
						<div class="left-span2-row2">
							<div class="bottonx fl" :style="{
            background: !infoList.RQM__p__ZQT_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__ZQT_QK1
              ? toDetail(
                  2,
                  'ZQT_QK1',
                  'RQM__p__ZQT_QK1',
                  '',
                  '一级减温左前馈'
                )
              : ''
          ">
								Q
							</div>
							<div class="buttonlable fl" :style="{
            background: !infoList.MKG.ZQT_1A_QS ? '#2AFC30' : 'red',
          }" @click="toIpt(infoList.MKG.ZQT_1A_QS, 'ZQT_1A_QS','ZQT_1A_QS','MKG')">
								{{infoList.MKG.ZQT_1A_QS?'串级':'单回路'}}</div>
						</div>
						<div class="left-span2-row3">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK2.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK2
              ? toDetail(
                  1,
                  'ZQT_XK2',
                  'XK__p__ZQT_XK2',
                  '',
                  '一级甲主汽温度副调'
                )
              : ''
          ">X</div>
						</div>
						<div></div>
					</div>
					<div class="left-span3 fl">
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_MAN1</div>
							<div class="valuex fl color"
								@click="toCompon(2, 'AV', 'RQM__p__ZQT_MAN1', 'ZQT_MAN1_AV_BM','一级甲减温水手操器')"
								@dblclick="Cclick(infoList.RQM__p__ZQT_MAN1.AV,'AV', 'RQM__p__ZQT_MAN1', 'ZQT_MAN1_AV_BM')">
								{{infoList.RQM__p__ZQT_MAN1.AV}}</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">ZQT_MV1A_HL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN1.OutT,
                    'ZQT_MV1A_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN1',
                   
                  )
                ">
								{{ infoList.RQM__p__ZQT_MAN1.OutT }}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_MV1A_LL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN1.OutB,
                    'ZQT_1A_SP_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN1',
                   
                  )
                ">
								{{ infoList.RQM__p__ZQT_MAN1.OutB }}
							</div>
						</div>
						<div class="left-span-col1 left-jg2">
							<div class="widthd fl">JWHT_1A_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'JWHT_1A_PVL', 'MKG', 'JWHT_1A_PVL_BM','一级左减温后温度滤波值')"
								@dblclick="Cclick(infoList.MKG.JWHT_1A_PVL,'JWHT_1A_PVL', 'MKG', 'JWHT_1A_PVL_BM')">
								{{infoList.MKG.JWHT_1A_PVL}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">JWHT_1A_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.JWHT_1A_TC,
                    'JWHT_1A_TC',
                     'JWHT_1A_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.JWHT_1A_TC }}
							</div>
						</div>
					</div>

				</div>
				<!-- 左边下半部分 -->
				<div>
					<div class="left-span1 fl">

						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1B_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_1B_PVL', 'MKG', 'ZQT_1B_PVL_BM','屏过B温度过程滤波值')"
								@dblclick="Cclick(infoList.MKG.ZQT_1B_PVL,'ZQT_1B_PVL', 'MKG', 'ZQT_1B_PVL_BM')">
								{{infoList.MKG.ZQT_1B_PVL}}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1B_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_1B_TC,
                    'ZQT_1B_TC',
                     'ZQT_1B_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_1B_TC }}
							</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">ZQT_1B_SPR</div>
							<div class="valuex fl color" @click="toCompon(2, 'SP', 'XK__p__ZQT_XK3', 'ZQT_XK3_SP_BM','一级乙主汽温度')"
								@dblclick="Cclick(infoList.XK__p__ZQT_XK3.SP,'SP', 'XK__p__ZQT_XK3', 'ZQT_XK3_SP_BM')">
								{{infoList.XK__p__ZQT_XK3.SP}}</div>
						</div>
						<div class="zlbutton" @click="toCompon(13,'ZQT1BSP','负荷气温补偿折现','风量','FM')">SP增量</div>
						<div class="left-span-col1 left-jg2">
							<div class="widthd fl">ZQT_1B_SP</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_1B_SP', 'IOG', 'ZQT_1B_SP_BM','乙一级减温控制点')"
								@dblclick="Cclick(infoList.IOG.ZQT_1B_SP,'ZQT_1B_SP', 'IOG', 'ZQT_1B_SP_BM')">
								{{infoList.IOG.ZQT_1B_SP}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1B_SPH</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_1B_SP_HL,
                    'ZQT_1B_SPH',
                     'ZQT_1B_SP_HL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_1B_SP_HL }}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_1B_SPL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_1B_SP_LL,
                    'ZQT_1B_SPL',
                     'ZQT_1B_SP_LL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_1B_SP_LL }}
							</div>
						</div>
					</div>
					<div class="left-span2 fl">
						<div class="lablebt1">
							右一级减温控制
						</div>
						<!-- 第一行 X 和A -->
						<div class="left-span2-row1 lablebt1">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK3.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK3
              ? toDetail(
                  1,
                  'ZQT_XK3',
                  'XK__p__ZQT_XK3',
                  '',
                  '一级乙主汽温度'
                )
              : ''
          ">X</div>
							<div class="bottonx fr" :style="{
              background:
              infoList.RQM__p__ZQT_MAN1.RM== 1 || infoList.RQM__p__ZQT_MAN2.RM== 1 || infoList.RQM__p__ZQT_MAN3.RM == 1 || infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }" @click="toCompon(0,'MFL_MANQW',4)">A</div>
						</div>
						<div class="left-span2-row2">
							<div class="bottonx fl" :style="{
            background: !infoList.RQM__p__ZQT_QK2.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__ZQT_QK2
              ? toDetail(
                  2,
                  'ZQT_QK2',
                  'RQM__p__ZQT_QK2',
                  '',
                  '一级减温右前馈'
                )
              : ''
          ">
								Q
							</div>

							<div class="buttonlable fl" :style="{
            background: !infoList.MKG.ZQT_1B_QS ? '#2AFC30' : 'red',
          }" @click="toIpt(infoList.MKG.ZQT_1B_QS, '此按钮','ZQT_1B_QS','MKG')">{{infoList.MKG.ZQT_1B_QS?'串级':'单回路'}}
							</div>
						</div>
						<div class="left-span2-row3">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK4.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK4
              ? toDetail(
                  1,
                  'ZQT_XK4',
                  'XK__p__ZQT_XK4',
                  '',
                  '一级主汽温度副调'
                )
              : ''
          ">X</div>
						</div>
						<div></div>
					</div>
					<div class="left-span3 fl">
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_MAN2</div>
							<div class="valuex fl color"
								@click="toCompon(2, 'AV', 'RQM__p__ZQT_MAN2', 'ZQT_MAN2_AV_BM','一级乙减温水手操器')"
								@dblclick="Cclick(infoList.RQM__p__ZQT_MAN2.AV,'AV', 'RQM__p__ZQT_MAN2', 'ZQT_MAN2_AV_BM')">
								{{infoList.RQM__p__ZQT_MAN2.AV}}</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">ZQT_MV1B_HL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN2.OutT,
                    'ZQT_MV1B_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN2',
                   
                  )
                ">
								{{ infoList.RQM__p__ZQT_MAN2.OutT }}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_MV1B_LL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN2.OutB,
                    'ZQT_MV1B_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN2',
                   
                  )
                ">
								{{ infoList.RQM__p__ZQT_MAN2.OutB }}
							</div>
						</div>
						<div class="left-span-col1 left-jg2">
							<div class="widthd fl">JWHT_1B_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'JWHT_1B_PVL', 'MKG', 'JWHT_1B_PVL_BM','一级右减温后温度滤波值')"
								@dblclick="Cclick(infoList.MKG.JWHT_1B_PVL,'JWHT_1B_PVL', 'MKG', 'JWHT_1B_PVL_BM')">
								{{infoList.MKG.JWHT_1B_PVL}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">JWHT_1B_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.JWHT_1A_TC,
                    'JWHT_1B_TC',
                     'JWHT_1B_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.JWHT_1B_TC }}
							</div>
						</div>
					</div>

				</div>
			</div>
			<!-- 右边 -->
			<div class="rightmain fl">
				<!-- 右边上半部分 -->
				<div class="rightmain-top">

					<!-- 右边第一列 -->
					<div class="left-span1 fl">
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_2A_SPH</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_2A_SP_HL,
                    'ZQT_2A_SPH',
                     'ZQT_2A_SP_HL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_2A_SP_HL }}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_2A_SPL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_2A_SP_LL,
                    'ZQT_2A_SPL',
                     'ZQT_2A_SP_LL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_2A_SP_LL }}
							</div>
						</div>
						<div class="left-span-col1 ">
							<div class="widthd fl">ZQT_2A_SP</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_2A_SP', 'IOG', 'ZQT_2A_SP_BM','甲二级减温控制点')"
								@dblclick="Cclick(infoList.IOG.ZQT_2A_SP,'ZQT_2A_SP', 'IOG', 'ZQT_2A_SP_BM')">
								{{infoList.IOG.ZQT_2A_SP}}</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">ZQT_2A_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_2A_PVL', 'MKG', 'ZQT_2A_PVL_BM','主汽温度过程滤波值')"
								@dblclick="Cclick(infoList.MKG.ZQT_2A_PVL,'ZQT_2A_PVL', 'MKG', 'ZQT_2A_PVL_BM')">
								{{infoList.MKG.ZQT_2A_PVL}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_2A_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_2A_TC,
                    'ZQT_2A_TC',
                     'ZQT_2A_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_2A_TC }}
							</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">JWHT_2A_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'JWHT_2A_PVL', 'MKG', 'JWHT_2A_PVL_BM','二级左减温后温度滤波值')"
								@dblclick="Cclick(infoList.MKG.JWHT_2A_PVL,'JWHT_2A_PVL', 'MKG', 'JWHT_2A_PVL_BM')">
								{{infoList.MKG.JWHT_2A_PVL}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">JWHT_2A_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.JWHT_2A_TC,
                    'JWHT_2A_TC',
                     'JWHT_2A_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.JWHT_2A_TC }}
							</div>
						</div>

					</div>
					<!-- 中间 -->
					<div class="right-span2 fl">
						<div class="lablebt">
							左二级减温控制
						</div>
						<!-- 第一行 X 和A -->
						<div class="right-span2-row1">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK5.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK5
              ? toDetail(
                  1,
                  'ZQT_XK5',
                  'XK__p__ZQT_XK5',
                  '',
                  '二级甲减温先控调节'
                )
              : ''
          ">X</div>
						</div>
						<div class="right-span2-row2">
							<div class="bottonx fl" :style="{
            background: !infoList.RQM__p__ZQT_QK3.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__ZQT_QK3
              ? toDetail(
                  2,
                  'ZQT_QK3',
                  'RQM__p__ZQT_QK3',
                  '',
                  '二级减温左前馈'
                )
              : ''
          ">
								Q
							</div>
							<div class="buttonlable fl" :style="{
            background: !infoList.MKG.ZQT_2A_QS ? '#2AFC30' : 'red',
          }" @click="toIpt(infoList.MKG.ZQT_2A_QS, 'ZQT_2A_QS','ZQT_2A_QS','MKG')">
								{{infoList.MKG.ZQT_2A_QS?'串级':'单回路'}}</div>
						</div>
						<div class="right-span2-row3">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK6.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK6
              ? toDetail(
                  1,
                  'ZQT_XK6',
                  'XK__p__ZQT_XK6',
                  '',
                  '二级甲减温先控副调节'
                )
              : ''
          ">X</div>
							<div class="bottonx fl" style="margin-left:20vw" :style="{
            background: !infoList.RQM__p__ZQT_QK5.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__ZQT_QK5
              ? toDetail(
                  2,
                  'ZQT_QK5',
                  'RQM__p__ZQT_QK5',
                  '',
                  '负荷前馈'
                )
              : ''
          ">
								Q
							</div>
						</div>
						<div></div>
					</div>
					<div class="left-span3 fl">
						<div>
							<div class="fl">
								<div class="bottonx" style="margin-right:1vw;" :style="{
              background:
              infoList.RQM__p__ZQT_MAN1.RM== 1 || infoList.RQM__p__ZQT_MAN2.RM== 1 || infoList.RQM__p__ZQT_MAN3.RM == 1 || infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }" @click="toCompon(0,'MFL_MANQW',4)">A</div>
							</div>
							<div class="fl">
								<div class="left-span-col1">
									<div class="widthd fl">ZQT_MAN3</div>
									<div class="valuex fl color"
										@click="toCompon(2, 'AV', 'RQM__p__ZQT_MAN3', 'ZQT_MAN3_AV_BM','二级甲减温水手操器')"
										@dblclick="Cclick(infoList.RQM__p__ZQT_MAN3.AV,'AV', 'RQM__p__ZQT_MAN3', 'ZQT_MAN3_AV_BM')">
										{{infoList.RQM__p__ZQT_MAN3.AV}}</div>
								</div>
								<div class="left-span-col1 left-jg1">
									<div class="widthd fl">ZQT_MV2A_HL</div>
									<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN3.OutT,
                    'ZQT_MV2A_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN3',
                   
                  )
                ">
										{{ infoList.RQM__p__ZQT_MAN3.OutT }}
									</div>
								</div>
								<div class="left-span-col1">
									<div class="widthd fl">ZQT_MV2A_LL</div>
									<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN3.OutB,
                    'ZQT_MV2A_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN3',
                   
                  )
                ">
										{{ infoList.RQM__p__ZQT_MAN3.OutB }}
									</div>
								</div>

							</div>

						</div>

					</div>

				</div>
				<!-- 右边下半部分 -->
				<div>

					<!-- 右边第一列 -->
					<div class="left-span1 fl">
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_2B_SPH</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_2B_SP_HL,
                    'ZQT_2B_SPH',
                     'ZQT_2B_SP_HL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_2B_SP_HL }}
							</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_2B_SPL</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_2B_SP_LL,
                    'ZQT_2B_SPL',
                     'ZQT_2B_SP_LL',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_2B_SP_LL }}
							</div>
						</div>
						<div class="left-span-col1 ">
							<div class="widthd fl">ZQT_2B_SP</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_2B_SP', 'IOG', 'ZQT_2B_SP_BM','乙一级减温控制点')"
								@dblclick="Cclick(infoList.IOG.ZQT_2B_SP,'ZQT_2B_SP', 'IOG', 'ZQT_2B_SP_BM')">
								{{infoList.IOG.ZQT_2B_SP}}</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">ZQT_2B_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'ZQT_2B_PVL', 'MKG', 'ZQT_2B_PVL_BM','主汽温度过程滤波值')"
								@dblclick="Cclick(infoList.MKG.ZQT_2B_PVL,'ZQT_2B_PVL', 'MKG', 'ZQT_2B_PVL_BM')">
								{{infoList.MKG.ZQT_2B_PVL}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">ZQT_2B_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.ZQT_2B_TC,
                    'ZQT_2B_TC',
                     'ZQT_2B_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.ZQT_2B_TC }}
							</div>
						</div>
						<div class="left-span-col1 left-jg1">
							<div class="widthd fl">JWHT_2B_PVL</div>
							<div class="valuex fl color" @click="toCompon(2, 'JWHT_2B_PVL', 'MKG', 'JWHT_2B_PVL_BM','二级右减温后温度滤波值')"
								@dblclick="Cclick(infoList.MKG.JWHT_2B_PVL,'JWHT_2B_PVL', 'MKG', 'JWHT_2B_PVL_BM')">
								{{infoList.MKG.JWHT_2B_PVL}}</div>
						</div>
						<div class="left-span-col1">
							<div class="widthd fl">JWHT_2B_TC</div>
							<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.MKG.JWHT_2B_TC,
                    'JWHT_2B_TC',
                     'JWHT_2B_TC',
                    'MKG',
                   
                  )
                ">
								{{ infoList.MKG.JWHT_2B_TC }}
							</div>
						</div>

					</div>
					<!-- 中间 -->
					<div class="right-span2 fl">
						<div class="lablebt">
							右二级减温控制
						</div>
						<!-- 第一行 X 和A -->
						<div class="right-span2-row1" style="margin-top:2vh;">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK7.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK7
              ? toDetail(
                  1,
                  'ZQT_XK7',
                  'XK__p__ZQT_XK7',
                  '',
                  '二级乙减温先控调节'
                )
              : ''
          ">X</div>
						</div>
						<div class="right-span2-row2">
							<div class="bottonx fl" :style="{
            background: !infoList.RQM__p__ZQT_QK4.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__ZQT_QK4
              ? toDetail(
                  2,
                  'ZQT_QK4',
                  'RQM__p__ZQT_QK4',
                  '',
                  '二级减温右前馈'
                )
              : ''
          ">
								Q
							</div>
							<div class="buttonlable fl" :style="{
            background: !infoList.MKG.ZQT_2B_QS ? '#2AFC30' : 'red',
          }" @click="toIpt(infoList.MKG.ZQT_2B_QS, '此按钮','ZQT_2B_QS','MKG')">{{infoList.MKG.ZQT_2B_QS?'串级':'单回路'}}
							</div>
						</div>
						<div class="right-span2-row3">
							<div class="bottonx fl" :style="{
            background: !infoList.XK__p__ZQT_XK8.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK8
              ? toDetail(
                  1,
                  'ZQT_XK8',
                  'XK__p__ZQT_XK8',
                  '',
                  '二级乙减温先控副调节'
                )
              : ''
          ">X</div>

						</div>
						<div></div>
					</div>
					<div class="left-span3 fl">
						<div>
							<div class="fl">
								<div class="bottonx" style="margin-right:1vw;" :style="{
              background:
              infoList.RQM__p__ZQT_MAN1.RM== 1 || infoList.RQM__p__ZQT_MAN2.RM== 1 || infoList.RQM__p__ZQT_MAN3.RM == 1 || infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }" @click="toCompon(0,'MFL_MANQW',4)">A</div>
							</div>
							<div class="fl">
								<div class="left-span-col1">
									<div class="widthd fl">ZQT_MAN4</div>
									<div class="valuex fl color"
										@click="toCompon(2, 'AV', 'RQM__p__ZQT_MAN4', 'ZQT_MAN4_AV_BM','二级乙减温水手操器')"
										@dblclick="Cclick(infoList.RQM__p__ZQT_MAN4.AV,'AV', 'RQM__p__ZQT_MAN4', 'ZQT_MAN4_AV_BM')">
										{{infoList.RQM__p__ZQT_MAN4.AV}}</div>
								</div>
								<div class="left-span-col1 left-jg1">
									<div class="widthd fl">ZQT_MV2B_HL</div>
									<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN4.OutT,
                    'ZQT_MV2B_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN4',
                   
                  )
                ">
										{{ infoList.RQM__p__ZQT_MAN4.OutT }}
									</div>
								</div>
								<div class="left-span-col1">
									<div class="widthd fl">ZQT_MV2B_LL</div>
									<div class="valuex fl colorx" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN4.OutB,
                    'ZQT_MV2B_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN4',
                   
                  )
                ">
										{{ infoList.RQM__p__ZQT_MAN4.OutB }}
									</div>
								</div>

							</div>

						</div>

					</div>

				</div>

			</div>
		</div>

		<div></div>

		<mflmanyManual v-if="mflmanyManual" :titname="Manualtitname" :numM="Manualnode" :mark="Manualmark"
			:manvar="Manualvar" @sendStatus="isClose" :infoList="infoList"></mflmanyManual>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
		<MfZQFOZX v-if="MfZQFOZX" @sendStatus='isClose' :infoList="infoList" :MfZQFOZXCS1="MfZQFOZXCS1"
			:MfZQFOZXCS2="MfZQFOZXCS2" :MfZQFOZXCS3="MfZQFOZXCS3" :MfZQFOZXCS4="MfZQFOZXCS4"></MfZQFOZX>

	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import mflmanyManual from "@/components/MflmanyManual.vue"; //多手操器
	import MfZQFOZX from "@/views/MfBoiler/MFZQFO_ZX/index.vue";
	export default {
		name: "MFL_ZQTC",
		components: {
			mflmanyManual,
			Historical,
			inputVal,
			MfZQFOZX
		},
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				isMshow: false,
				flag: 0,
				Manualmark: '',
				Manualvar: '',
				mflmanyManual: false,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				MfZQFOZXCS1: "",
				MfZQFOZXCS2: "",
				MfZQFOZXCS3: "",
				MfZQFOZXCS4: "",
				MfZQFOZX: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
				spotArr: [],
				authInfo: [],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
		},
		computed: {},
		mounted() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'mfkfirstcontol'
							break
						case 3:
							pathname = 'mfRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "isPshow01", false);
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'MfZQFOZX':
						return this.MfZQFOZX = val1
					case 'mflmanyManual':
						return this.mflmanyManual = val1
				}
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				switch (key) {
					case 0:
						return this.mflmanyManual = true, this.Manualtitname = name, this.Manualnode = name2, this
							.Manualmark = name3, this.Manualvar = titname
					case 1:
						return (
							(this.isFshow = true),
							(this.Firstcontrolname = name),
							(this.Firstcontrolnode = name2),
							(this.Firstcontroltitname = titname)
						);
					case 2:
						return ((this.isHshow = true), (this.chName = name4));
					case 13:
						return this.MfZQFOZX = true, this.MfZQFOZXCS1 = name, this.MfZQFOZXCS2 = name2, this.MfZQFOZXCS3 =
							name3, this.MfZQFOZXCS4 = titname
				}
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mflmac {
		width: 83vw;
		height: 85vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 3.5vw;
			font-size: 0.9vw;
		}

		.left-span1 {
			width: 10vw;
			height: 30vh;
			// border: 1px saddlebrown solid;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 0.9vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 1.5vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 1.2vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 83vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 80vw;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			cursor: pointer;
			position: relative;
			z-index: 2;
		}

		.leftmain {
			width: 40vw;
			height: 72vh;
			// border: 1px solid saddlebrown;
			margin-left: 1vw;
		}

		.main {
			margin-top: 4vh;
			color: #8aeaff;
			font-size: 1vw;
		}

		.left-span-col1 {
			width: 11vw;
			height: 3vh;
			line-height: 3vh;
			background-color: #001b2860;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
		}

		.valuex {
			width: 3vw;
			text-align: center;
			padding-left: 0.8vw;
			cursor: pointer;
			position: relative;
			z-index: 100;
		}

		.widthd {
			width: 6.5vw;
			padding-left: 0.5vw;
		}

		.colorx {
			color: #01ffba;
		}

		.left-span2 {
			width: 23vw;
			height: 21vh;
			background-image: url("~@/assets/images/MFL_ZQWD_01.png");
			background-size: 100% 100%;
			margin-left: -5vw;
			margin-top: 3vh;
			// position: ;
			// z-index: -1;
		}

		.right-span2 {
			width: 17vw;
			height: 21vh;
			background-image: url("~@/assets/images/MFL_ZQWD_02.png");
			background-size: 100% 100%;
			margin-left: 0.5vw;
			margin-top: 2vh;
		}

		.lablebt {
			margin: -2vh 0 0 9vw;
			font-size: 1.2vw;
			width: 10vw;
		}

		.lablebt1 {
			margin: -4.5vh 0 0 9vw;
			font-size: 1.2vw;
		}

		.left-jg1 {
			margin-top: 1vh;
		}

		.left-jg2 {
			margin-top: 3vh;
		}

		.leftmain-top {
			height: 35vh;
		}

		.rightmain-top {
			height: 35vh;
		}

		.zlbutton {
			width: 4vw;
			line-height: 3vh;
			background-color: #22fff7;
			border-radius: 0vh;
			color: #000;
			margin: 3.5vh 0 0 3vw;
			text-align: center;
			cursor: pointer;
			position: relative;
			z-index: 100;
		}

		.left-span2-row1 {
			margin-left: 7.5vw;
			margin-top: 4.5vh;
			height: 3vh;
		}

		.right-span2-row1 {
			margin-left: 3.5vw;
			margin-top: 1.5vh;
			height: 3vh;
		}

		.right-span2-row11 {
			margin-left: 3.7vw;
			margin-top: 4.5vh;
			height: 3vh;
		}

		.left-span2-row2 {
			margin-left: 10.4vw;
			margin-top: 3vh;
			height: 3vh;
		}

		.right-span2-row2 {
			margin-left: 6vw;
			margin-top: 3vh;
			height: 3vh;
		}

		.left-span2-row3 {
			margin-left: 14.8vw;
			margin-top: 1vh;
			height: 3vh;
		}

		.right-span2-row3 {
			margin-left: 4.5vw;
			margin-top: 3vh;
			height: 3vh;
		}

		.buttonlable {
			width: 5vw;
			height: 3vh;
			background: #f1f7f7;
			line-height: 3vh;
			color: #000;
			text-align: center;
			margin-left: 2vw;

		}

		.left-span3 {
			margin: 7vh 0 0 0.5vw;
		}
	}
</style>
