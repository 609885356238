var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflmac drag",attrs:{"id":"YCFC"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_YCF_C")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("一次风压优化控制模型")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"leftmain-col"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_A_SPB")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'YCF_A_SPB2', 'MKG', 'YCF_A_SPB_BM','1#一次风设定值偏置')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.YCF_A_SPB2,'YCF_A_SPB2', 'MKG', 'YCF_A_SPB_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_A_SPB2)+" ")])]),_c('div',{staticClass:"fybutton",on:{"click":function($event){return _vm.toCompon(13,'ZQFS','最小风量折线','GML','FM')}}},[_vm._v("风压SP")]),_c('div',{staticClass:"leftmain-col leftmain-jg"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_A_SP")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'YCF_A_SP', 'IOG', 'YCF_A_SP_BM','1#一次风设定值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG,'YCF_A_SP', 'IOG', 'YCF_A_SP_BM')}}},[_vm._v(_vm._s(_vm.infoList.IOG.YCF_A_SP)+" ")])]),_c('div',{staticClass:"leftmain-col leftmain-jg"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_SPH")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCF_SP_HL,
                    'YCF_SPH',
                     'YCF_SP_HL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_SP_HL)+" ")])]),_c('div',{staticClass:"leftmain-col"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_SPL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCF_SP_LL,
                    'YCF_SPL',
                     'YCF_SP_LL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_SP_LL)+" ")])])]),_c('div',{staticClass:"centermain fl"},[_c('div',{staticClass:"fl centmian-left"},[_c('div',{staticClass:"leftmain-col centmian-jg"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCI_PVA_HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCI_PVA_HL,
                    'YCI_PVA_HL',
                     'YCI_PVA_HL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCI_PVA_HL)+" ")])]),_c('div',{staticClass:"leftmain-col centmian-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCI_PVB_HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCI_PVA_LL,
                    'YCI_PVA_LL',
                     'YCI_PVA_LL',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCI_PVA_LL)+" ")])]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.XK__p__YCF_XK1.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK__p__YCF_XK1
              ? _vm.toDetail(
                  1,
                  'YCF_XK1',
                  'XK__p__YCF_XK1',
                  '',
                  '一次风压先控调节'
                )
              : ''}}},[_vm._v("X")]),_c('div',{staticClass:"leftmain-colx centmian-jg2 fl"},[_c('div',{staticClass:"widthd fl"},[_vm._v("BP-AV")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'XK__p__YCF_XK1', 'YCF_XK1_AV_BM','一次风压先控调节')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.XK__p__YCF_XK1.AV,'AV', 'XK__p__YCF_XK1', 'YCF_XK1_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.XK__p__YCF_XK1.AV)+" ")])])]),_c('div',{staticClass:"leftmain-col centmian-jg3"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_TC")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCF_TC,
                    'YCF_TC',
                     'YCF_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_TC)+" ")])]),_c('div',{staticClass:"leftmain-col centmian-jg4"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_A_PVL")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'YCF_A_PVL', 'MKG', 'YCF_A_PVL_BM','A一次风压滤波值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.YCF_A_PVL,'YCF_A_PVL', 'MKG', 'YCF_A_PVL_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_A_PVL)+" ")])])]),_c('div',{staticClass:"fl centmian-right"},[_c('div',{staticClass:"bottonx centmian-jg5",style:({
            background: !_vm.infoList.RQM__p__YCF_RSF1.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__YCF_RSF1
              ? _vm.toDetail(
                  3,
                  'YCF_RSF1',
                  'RQM__p__YCF_RSF1',
                  '',
                  '一次风机电流纠偏'
                )
              : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"leftmain-col1 centmian-jg6"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF_MVBA_B")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCF_MVA_B,
                    'YCF_MVA_B',
                     'YCF_MVA_B',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_MVA_B)+" ")])]),_c('div',{staticClass:"bottonx centmian-jg7",style:({
                      background:
                      _vm.infoList.RQM__p__YCF_MAN1.RM==1  || _vm.infoList.RQM__p__YCF_MAN2.RM==1  ? '#2AFC30' : 'red'
                      }),on:{"click":function($event){return _vm.toCompon(0,'MFL_MANYCF',2)}}},[_vm._v("A")]),_c('div',{staticClass:"leftmain-col1 centmian-jg8"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF_MVBB_B")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCF_MVB_B,
                    'YCF_MVB_B',
                     'YCF_MVB_B',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCF_MVB_B)+" ")])]),_c('div',{staticClass:"centmian-jg9"},[_c('div',{staticClass:"bottonx fl",style:({
            background: !_vm.infoList.RQM__p__YCF_QK1.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RQM__p__YCF_QK1
              ? _vm.toDetail(
                  2,
                  'YCF_QK1',
                  'RQM__p__YCF_QK1',
                  '',
                  '一次风前馈'
                )
              : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"leftmain-col centmian-jg10 fl"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQF_SP_TC")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.ZQF_SP_TC,
                    'ZQF_SP_TC',
                     'ZQF_SP_TC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.ZQF_SP_TC)+" ")])])])])]),_c('div',{staticClass:"rightmain fl"},[_c('div',{staticClass:"leftmain-col1 "},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCFI_JPPC")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YCJI_JPPC,
                    'YCJI_JPPC',
                     'YCJI_JPPC',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YCJI_JPPC)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg1"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF_MAN1")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'RQM__p__YCF_MAN1', 'YCF_MAN1_AV_BM','1#一次风机手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.RQM__p__YCF_MAN1.AV,'AV', 'RQM__p__YCF_MAN1', 'YCF_MAN1_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__YCF_MAN1.AV)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg2"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVA-HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__YCF_MAN1.OutT,
                    'YCF-MVA-HL',
                     'OutT',
                    'RQM__p__YCF_MAN1'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__YCF_MAN1.OutT)+" ")])]),_c('div',{staticClass:"leftmain-col1 "},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVA-LL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__YCF_MAN1.OutB,
                    'YCF-MVA-LL',
                     'OutB',
                    'RQM__p__YCF_MAN1'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__YCF_MAN1.OutB)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg3"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MAN2")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'RQM__p__YCF_MAN2', 'YCF_MAN2_AV_BM','2#一次风机手操器')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.RQM__p__YCF_MAN2.AV,'AV', 'RQM__p__YCF_MAN2', 'YCF_MAN2_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__YCF_MAN2.AV)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg2"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVB-HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__YCF_MAN2.OutT,
                    'YCF-MVB-HL',
                     'OutT',
                    'RQM__p__YCF_MAN2'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__YCF_MAN2.OutT)+" ")])]),_c('div',{staticClass:"leftmain-col1 "},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVB-LL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RQM__p__YCF_MAN2.OutB,
                    'YCF-MVB-LL',
                     'OutB',
                    'RQM__p__YCF_MAN2'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RQM__p__YCF_MAN2.OutB)+" ")])])])]),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.MfZQFOZX)?_c('MfZQFOZX',{attrs:{"infoList":_vm.infoList,"MfZQFOZXCS1":_vm.MfZQFOZXCS1,"MfZQFOZXCS2":_vm.MfZQFOZXCS2,"MfZQFOZXCS3":_vm.MfZQFOZXCS3,"MfZQFOZXCS4":_vm.MfZQFOZXCS4},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }