<template>
	<div class="mfzqfozx drag" id="mfzqfozx" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>{{ projectData.project }}_{{MfZQFOZXCS1}}_ZX</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">{{MfZQFOZXCS2}}</div>
		<div class="main">
			<div class="row1">
				<div class="row1-span1 fl"> 序号</div>
				<div v-if="MfZQFOZXCS1!='ZQFS'" class="row1-span2 color1 fl"> {{MfZQFOZXCS1}}_IN</div>
				<div v-if="MfZQFOZXCS1!='ZQFS'" class="row1-span3 color1 fl"> {{MfZQFOZXCS1}}_AV</div>
				<div v-if="MfZQFOZXCS1=='ZQFS'" class="row1-span2 color1 fl"> {{MfZQFOZXCS3}}_IN</div>
				<div v-if="MfZQFOZXCS1=='ZQFS'" class="row1-span3 color1 fl"> {{MfZQFOZXCS4}}_AV</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 1</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__1__rr__,
                    MfZQFOZXCS1+'_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__1__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__1__rr__,
                    MfZQFOZXCS1+'_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__1__rr__}}
				</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 2</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__2__rr__,
                    MfZQFOZXCS1+'_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__2__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__2__rr__,
                    MfZQFOZXCS1+'_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__2__rr__}}
				</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 3</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__3__rr__,
                    MfZQFOZXCS1+'_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__3__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__3__rr__,
                    MfZQFOZXCS1+'_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__3__rr__}}
				</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 4</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__4__rr__,
                    MfZQFOZXCS1+'_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__4__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__4__rr__,
                    MfZQFOZXCS1+'_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__4__rr__}}
				</div>
			</div>

			<div class="row2">
				<div class="row1-span1 fl"> 5</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__5__rr__,
                    MfZQFOZXCS1+'_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__5__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__5__rr__,
                    MfZQFOZXCS1+'_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__5__rr__}}
				</div>
			</div>

			<div class="row2">
				<div class="row1-span1 fl"> 6</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__6__rr__,
                    MfZQFOZXCS1+'_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__6__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__6__rr__,
                    MfZQFOZXCS1+'_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__6__rr__}}
				</div>

			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 7</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__7__rr__,
                    MfZQFOZXCS1+'_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__7__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__7__rr__,
                    MfZQFOZXCS1+'_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__7__rr__}}
				</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 8</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__8__rr__,
                    MfZQFOZXCS1+'_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__8__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__8__rr__,
                    MfZQFOZXCS1+'_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__8__rr__}}
				</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 9</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__9__rr__,
                    MfZQFOZXCS1+'_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__9__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__9__rr__,
                    MfZQFOZXCS1+'_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__9__rr__}}
				</div>
			</div>

			<div class="row2">
				<div class="row1-span1 fl"> 10</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__10__rr__,
                    MfZQFOZXCS1+'_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__10__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__10__rr__,
                    MfZQFOZXCS1+'_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__10__rr__}}
				</div>
			</div>
			<div class="row2">
				<div class="row1-span1 fl"> 11</div>
				<div class="row1-span2 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].X__ll__11__rr__,
                    MfZQFOZXCS1+'_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].X__ll__11__rr__}}
				</div>
				<div class="row1-span3 fl" @click="
                  toIpt(
                    infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__11__rr__,
                    MfZQFOZXCS1+'_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__'+ MfZQFOZXCS1 ,
                   
                  )
                ">
					{{infoList['MKG__p__'+MfZQFOZXCS1].Y__ll__11__rr__}}
				</div>
			</div>
			<div class="row3 ">
				<div class="fl " style=" margin-left:8vw;">IN = </div>
				<div class="fl " @click="toCompon(2,'GML_SP', 'MKG', 'GML_SP_BM','总给煤指令')"
					@dblclick="Cclick(infoList.MKG.GML_SP,'GML_SP', 'MKG', 'GML_SP_BM')">{{infoList.MKG.GML_SP}}</div>

				<div class="fl " style="margin-left:1vw;">AV = </div>
				<div v-if="MfZQFOZXCS1!='ZQFS'" class="fl "
					@click="toCompon(2,MfZQFOZXCS1 +'_AV', 'MKG', MfZQFOZXCS1 +'_AV_BM','屏过出口设定值输出')"
					@dblclick="Cclick(infoList.MKG[MfZQFOZXCS1 +'_AV'],MfZQFOZXCS1 +'_AV', 'MKG', MfZQFOZXCS1 +'_AV_BM')">
					{{infoList.MKG[MfZQFOZXCS1 +'_AV']}}</div>
				<div v-if="MfZQFOZXCS1=='ZQFS'" class="fl " @click="toCompon(2,'SFF_MIN', 'MKG', 'SFF_M_IN_BM')"
					@dblclick="Cclick(infoList.MKG.SFF_MIN,'SFF_MIN', 'MKG','SFF_M_IN_BM')">{{infoList.MKG.SFF_MIN}}
				</div>

			</div>
		</div>

		<div v-if="MfZQFOZXCS1=='ZQFO'" class="main-bottom" style="margin-top:1vh">
			<div class="fl" style="margin-left:9vw">
				MFL_A_SPB =
			</div>
			<div class="fl color1" @click="
                  toIpt(
                    infoList.MKG.YQO_SP_B2,
                    'YQO_SP_B2',
                     'YQO_SP_B2',
                    'MKG',
                   
                  )
                ">
				{{infoList.MKG.YQO_SP_B2}}
			</div>
		</div>

		<div v-if="MfZQFOZXCS1=='ZQFO'" class="main-bottom">
			<div class="fl" style="margin-left:9vw">
				MYP_A_SP = _AV + SPB =
			</div>
			<div class="fl" @click="toCompon(2,'YQO_SP', 'IOG', 'YQO_SP_BM','烟气氧量设定值')"
				@dblclick="Cclick(infoList.IOG.YQO_SP,'YQO_SP', 'IOG', 'YQO_SP_BM')"> {{infoList.IOG.YQO_SP}}</div>
		</div>


		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isClose" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList"></Manual>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "MfZQFOZX_ZX",
		components: {
			Manual,
			Historical,
			inputVal,
		},
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
			MfZQFOZXCS1: [String, Number],
			MfZQFOZXCS2: [String, Number],
			MfZQFOZXCS3: [String, Number],
			MfZQFOZXCS4: [String, Number],

		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				isMshow: false,
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
				spotArr: [],
				authInfo: [],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
		},
		computed: {},
		mounted() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = "firstcontol";
							break;
						case 2:
							pathname = "kfirstcontol";
							break;
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "MfZQFOZX", false);
			},
			isClose() {
				this.isMshow = false;
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				switch (key) {
					case 0:
						return (
							(this.isMshow = true),
							(this.Manualname = name),
							(this.Manualnode = name2),
							(this.ManualAname = name3),
							(this.Manualtitname = titname)
						);
					case 1:
						return (
							(this.isFshow = true),
							(this.Firstcontrolname = name),
							(this.Firstcontrolnode = name2),
							(this.Firstcontroltitname = titname)
						);
					case 2:
						return ((this.isHshow = true), (this.chName = name4));
				}
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mfzqfozx {
		width: 40vw;
		height: 78vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 3.5vw;
			font-size: 0.9vw;
		}

		.left-span1 {
			width: 10vw;
			height: 30vh;
			// border: 1px saddlebrown solid;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 40vw;
			font-family: MicrosoftYaHei;
			font-size: 0.8vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			// padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 0.8vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 1.2vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 1.2vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 40vw;
			height: 4vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 37vw;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			margin: 1.5vh 0 0 7.8vw;
		}

		.main {
			margin-top: 4vh;
			color: #8aeaff;
			font-size: 1vw;
			width: 30vw;
			height: 52vh;
			// background-color: #00e4ff20;
			border: 1px solid #00e4ff40;
			margin: 2vh 0 0 5vw;

		}

		.row1 {
			width: 30vw;
			height: 4vh;
			border-bottom: 1px solid #00e4ff40;
			font-size: 1.2vw;
			line-height: 4vh;
		}

		.row2 {
			width: 30vw;
			height: 4vh;
			//  border-bottom:1px solid #00e4ff40;
			font-size: 1.2vw;
			line-height: 4vh;
		}

		.row3 {
			width: 30vw;
			height: 4vh;
			border-top: 1px solid #00e4ff40;
			font-size: 1.2vw;
			line-height: 4vh;

		}

		.row1-span1 {
			width: 5vw;
			text-align: center;
		}

		.row1-span2 {
			width: 11.4vw;
			text-align: center;
			border-left: 1px solid #00e4ff40;
			color: #01ffba;
			cursor: pointer;
		}

		.row1-span3 {
			width: 11.4vw;
			text-align: center;
			border-left: 1px solid #00e4ff40;
			color: #01ffba;
			cursor: pointer;
		}

		.color1 {
			color: #01ffba;
		}

		.widthx {
			width: 11.4vw;
			text-align: center;
			line-height: 4vh;
			color: #8aeaff;
		}

		.main-bottom {
			width: 30vw;
			height: 6vh;
			;
			margin: 0 0 0 1vw;
			font-size: 1.2vw;
			height: 5vh;
			color: #8aeaff;
			text-align: center;

		}

		// .bottom-jg{
		//   margin:0 0 0 7.7vw;
		// }
		.valuex {
			width: 3vw;
			color: #01ffba;
		}
	}
</style>
