<template>
	<div class="mfltpc drag" id="LTPC" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>{{ projectData.project }}_LTP_C</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">引风机优化控制模型</div>
		<div class="main">
			<div class="row1">
				<div class="fl row1-span1">
					<div class="row1-span2-col1">
						<div class="widthwd1 color1 fl">LTP_SPH</div>
						<div class="widthwd2 color2 fl" @click="
                toIpt(infoList.MKG.LTP_SP_HL, 'LTP_SPH', 'LTP_SP_HL', 'MKG')
              ">
							{{ infoList.MKG.LTP_SP_HL }}
						</div>
						<div></div>
					</div>
					<div class="row1-span2-col1">
						<div class="widthwd1 color1 fl">LTP_SPL</div>
						<div class="widthwd2 color2 fl" @click="
                toIpt(infoList.MKG.LTP_SP_LL, ' LTP_SPL', 'LTP_SP_LL', 'MKG')
              ">
							{{ infoList.MKG.LTP_SP_LL }}
						</div>
					</div>
					<div class="row1-span2-col1">
						<div class="widthwd1 color1 fl">LTP_SP</div>
						<div class="widthwd2  fl" @click="toCompon(2, 'LTP_SP', 'IOG', 'LTP_SP_BM','炉膛压力设定值')"
							@dblclick="Cclick(infoList.IOG.LTP_SP,'LTP_SP', 'IOG', 'LTP_SP_BM')">{{infoList.IOG.LTP_SP}}
						</div>
					</div>
					<div class="left-bottom">
						<div class="row1-span4-col1 fl">
							<div class="fl">
								<div class="ylbutton ylbutton-jg"
									:style="{ background: infoList.MKG.LTP_SEL==1 ? '#0ef7ff' : '25495e'}"
									@click="toIpt('1', '水位1','LTP_SEL','MKG','true')">负压1</div>
								<div class="ylbutton ylbutton-jg"
									:style="{ background: infoList.MKG.LTP_SEL==2 ? '#0ef7ff' : '25495e'}"
									@click="toIpt('2', '水位2','LTP_SEL','MKG','true')">负压2</div>
								<div class="ylbutton ylbutton-jg"
									:style="{ background: infoList.MKG.LTP_SEL==3 ? '#0ef7ff' : '25495e'}"
									@click="toIpt('3', '水位3','LTP_SEL','MKG','true')">负压3</div>
								<div class="ylbutton ylbutton-jg"
									:style="{ background: infoList.MKG.LTP_SEL==4 ? '#0ef7ff' : '25495e'}"
									@click="toIpt('4', '水位4','LTP_SEL','MKG','true')">负压4</div>
							</div>
							<div class="fl">
								<div class="widthwd4  "
									@click="toCompon(2, 'LTP_A_PV', 'IOG', 'LTP_A_PV_BM','炉膛压力过程值1')"
									@dblclick="Cclick(infoList.IOG.LTP_A_PV,'LTP_A_PV', 'IOG', 'LTP_A_PV_BM')">
									{{infoList.IOG.LTP_A_PV}}
								</div>
								<div class="widthwd4 " @click="toCompon(2, 'LTP_B_PV', 'IOG', 'LTP_B_PV_BM','炉膛压力过程值2')"
									@dblclick="Cclick(infoList.IOG.LTP_B_PV,'LTP_B_PV', 'IOG', 'LTP_B_PV_BM')">
									{{infoList.IOG.LTP_B_PV}}
								</div>

								<div class="widthwd4 " @click="toCompon(2, 'LTP_C_PV', 'IOG', 'LTP_C_PV_BM','炉膛压力过程值3')"
									@dblclick="Cclick(infoList.IOG.LTP_C_PV,'LTP_C_PV', 'IOG', 'LTP_C_PV_BM')">
									{{infoList.IOG.LTP_C_PV}}
								</div>
								<div class="widthwd4 " @click="toCompon(2, 'LTP_D_PV', 'IOG', 'LTP_D_PV_BM','炉膛压力过程值4')"
									@dblclick="Cclick(infoList.IOG.LTP_D_PV,'LTP_D_PV', 'IOG', 'LTP_D_PV_BM')">
									{{infoList.IOG.LTP_D_PV}}
								</div>
							</div>
							<div class="fl row1-span2-jg1">
								<div>
									<div class="widthwd5 color1 fl ">LTP-TC</div>
									<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.LTP_TC, ' LTP_TC', 'LTP_TC', 'MKG')
              ">
										{{ infoList.MKG.LTP_TC }}
									</div>
								</div>
								<div>
									<div class="widthwd5 color1 fl">LTP-C</div>
									<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.LTP_C, ' LTP_C', 'LTP_C', 'MKG')
              ">
										{{ infoList.MKG.LTP_C }}
									</div>
								</div>
								<div>
									<div class="widthwd5 color1 fl">LTP-PV</div>
									<div @click="toCompon(2, 'LTP_PV', 'MKG', 'LTP_PV_BM','炉膛负压过程值')"
										@dblclick="Cclick(infoList.MKG.LTP_PV,'LTP_PV', 'MKG', 'LTP_PV_BM')">
										{{infoList.MKG.LTP_PV}}
									</div>
								</div>

							</div>
						</div>
						<div class="fl row1-span4-col2">
							<div>
								<div class="widthwd6 color1 fl ">YFI_PVA_HL</div>
								<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.YFI_PVA_HL, ' YFI_PVA_HL', 'YFI_PVA_HL', 'MKG')
              ">
									{{ infoList.MKG.YFI_PVA_HL }}
								</div>
							</div>
							<div>
								<div class="widthwd6 color1 fl ">YFI_PVB_HL</div>
								<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.YFI_PVB_HL, ' YFI_PVB_HL', 'YFI_PVB_HL', 'MKG')
              ">
									{{ infoList.MKG.YFI_PVB_HL }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fl row1-span2 row1-span2-jg">
					<div>
						<div class="row1-span2-jg maincenter-jg fl">
							<div class="bottonx fl" style="margin-left:-4vw;" :style="{
            background: !infoList.XK__p__LTP_XK1.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__LTP_XK1
              ? toDetail(
                  1,
                  'LTP_XK1',
                  'XK__p__LTP_XK1',
                  '',
                  '引风先控调节'
                )
              : ''
          ">X</div>
							<div class="widthwd5 color1 fl ">BP-AV</div>
							<div class="widthwd color fl"
								@click="toCompon(2, 'AV', 'XK__p__LTP_XK1', 'LTP_XK1_AV_BM','引风先控调节')"
								@dblclick="Cclick(infoList.XK__p__LTP_XK1.AV,'AV', 'XK__p__LTP_XK1', 'LTP_XK1_AV_BM')">
								{{infoList.XK__p__LTP_XK1.AV}}
							</div>
						</div>
						<div class="fl">
							<div class="row1-span2-jg maincenter-jg1 ">
								<div class="widthwd1 color1 fl ">LTP_MVBA_B</div>
								<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.LTP_MVBA_B, ' LTP_MVBA_B', 'LTP_MVBA_B', 'MKG')
              ">
									{{ infoList.MKG.LTP_MVBA_B }}
								</div>
							</div>

							<div class="row1-span2-jg maincenter-jg1 " style="margin-top:12vh;">

								<div class="widthwd1 color1 fl ">LTP_MVBB_B</div>
								<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.LTP_MVBB_B, ' LTP_MVBB_B', 'LTP_MVBB_B', 'MKG')
              ">
									{{ infoList.MKG.LTP_MVBB_B }}
								</div>
							</div>
							<div style="margin-top:3vh;margin-left:4vw;">
								<div class="bottonx fl" :style="{
            background: !infoList.RQM__p__LTP_QK1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__LTP_QK1
              ? toDetail(
                  2,
                  'LTP_QK1',
                  'RQM__p__LTP_QK1',
                  '',
                  '炉膛负压前馈'
                )
              : ''
          ">Q
								</div>
								<div class="row1-span2-jg maincenter-jg1 ">

									<div class="widthwd1 color1 fl ">SFF_SP_TC</div>
									<div class="widthwd color2 fl" @click="
                toIpt(infoList.MKG.SFF_SP_TC, ' SFF_SP_TC', 'SFF_SP_TC', 'MKG')
              ">
										{{ infoList.MKG.SFF_SP_TC }}
									</div>
								</div>
							</div>


						</div>

					</div>

					<div class="row1-span2-col2">

					</div>

				</div>
				<!-- 右侧R和A -->
				<div class="fl row1-span14">
					<div class="bottonx" :style="{
            background: !infoList.RQM__p__ZRQ_RSF1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__LTP_RSF1
              ? toDetail(
                  3,
                  'LTP_RSF1',
                  'RQM__p__LTP_RSF1',
                  '',
                  '引风机电流纠偏'
                )
              : ''
          ">
						R
					</div>
					<div class="bottonx row1-span14-col1" :style="{
                      background:
                      infoList.RQM__p__LTP_MAN1.RM==1  || infoList.RQM__p__LTP_MAN2.RM==1  ? '#2AFC30' : 'red'
                      }" @click="toCompon(0,'MFL_MANYF',2)">A</div>

				</div>
				<!-- 最右边 -->
				<div class="fl row1-span3">
					<div class="row1-span3-col1 row1-span2-jg2">
						<div class="widthwd3 color1 fl">YFJI_JPPC</div>
						<div class="widthwd2 color2 fl" @click="
                  toIpt(
                    infoList.MKG.YFJI_JPPC,
                    'YFJI_JPPC',
                     'YFJI_JPPC',
                    'MKG',
                   
                  )
                ">
							{{ infoList.MKG.YFJI_JPPC }}
						</div>

					</div>
					<div class="row1-span3-col1 span3-jg">
						<div class="widthwd3 color1 fl">LTP_MAN1</div>
						<div class="widthwd2 color1 fl"
							@click="toCompon(2, 'AV', 'RQM__p__LTP_MAN1', 'LTP_MAN1_AV_BM','1#引风机变频手操器')"
							@dblclick="Cclick(infoList.RQM__p__LTP_MAN1.AV,'AV', 'RQM__p__LTP_MAN1', 'LTP_MAN1_AV_BM')">
							{{infoList.RQM__p__LTP_MAN1.AV}}
						</div>
					</div>
					<div>
						<div class="fl">
							<div class="row1-span3-col1 span3-jg">
								<div class="widthwd3 color1 fl">LTP-MVBA-HL</div>
								<div class="widthwd2 color2 fl" @click="toIpt(infoList.RQM__p__LTP_MAN1.OutT,' LTP-MVBA-HL','OutT','RQM__p__LTP_MAN1')">
									{{ infoList.RQM__p__LTP_MAN1.OutT }}
								</div>
							</div>
							<div class="row1-span3-col1 ">
								<div class="widthwd3 color1 fl"> LTP-MVBA-LL</div>
								<div class="widthwd2 color2 fl" @click="toIpt(infoList.RQM__p__LTP_MAN1.OutB,' LTP-MVBA-LL','OutB','RQM__p__LTP_MAN1') ">
									{{ infoList.RQM__p__LTP_MAN1.OutB }}
								</div>
							</div>
							<div class="row1-span3-col1 span3-jg" style="margin-top:4.2vh">
								<div class="widthwd3 color1 fl">LTP-MAN2</div>
								<div class="widthwd2 color1 fl"
									@click="toCompon(2, 'AV', 'RQM__p__LTP_MAN2', 'LTP_MAN2_AV_BM','2#引风机变频手操器')"
									@dblclick="Cclick(infoList.RQM__p__LTP_MAN2.AV,'AV', 'RQM__p__LTP_MAN2', 'LTP_MAN2_AV_BM')">
									{{infoList.RQM__p__LTP_MAN2.AV}}

								</div>
							</div>
							<div class="row1-span3-col1 span3-jg">
								<div class="widthwd3 color1 fl">LTP_MVBB_HL</div>
								<div class="widthwd2 color2 fl" @click="
                toIpt(
                  infoList.RQM__p__LTP_MAN2.OutT,
                  ' LTP-MVBB-HL',
                  'OutT',
                  'RQM__p__LTP_MAN2'
                )
              ">
									{{ infoList.RQM__p__GMJ_MAN2.OutT }}
								</div>
							</div>
							<div class="row1-span3-col1 ">
								<div class="widthwd3 color1 fl">LTP_MVBB_LL</div>
								<div class="widthwd2 color2 fl" @click="
                toIpt(
                  infoList.RQM__p__LTP_MAN2.OutB,
                  ' LTP-MVBB-LL',
                  'OutB',
                  'RQM__p__LTP_MAN2'
                )
              ">
									{{ infoList.RQM__p__GMJ_MAN2.OutB }}
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>

		<mflmanyManual v-if="mflmanyManual" :titname="Manualtitname" :numM="Manualnode" :mark="Manualmark"
			:manvar="Manualvar" @sendStatus="isClose" :infoList="infoList"></mflmanyManual>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
	</div>
</template>
<script>
	import inputVal from "@/components/inputVal.vue" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	// import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	// import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import mflmanyManual from "@/components/MflmanyManual.vue"; //多手操器
	export default {
		name: "MFL_LTP_C",
		components: {
			mflmanyManual,
			Historical,
			inputVal,
			Rsf
		},
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		data: () => {
			return {
				chName: '',
				mflmanyManual: false,
				Manualmark: '',
				Manualvar: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				isMshow: false,
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				bodyHeight: "",
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
				spotArr: [],
				authInfo: [],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
		},
		computed: {},
		mounted() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'mfkfirstcontol'
							break
						case 3:
							pathname = 'mfRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			toIpt(data, name, historyname, node, status) {
				this.$refs.inputVal.open(data, name, historyname, node, status);
			},
			closeCompon() {
				this.$emit("sendStatus", "isPshow06", false);
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'MfZQFOZX':
						return this.MfZQFOZX = val1
					case 'mflmanyManual':
						return this.mflmanyManual = val1
				}
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.mflmanyManual = true, this.Manualtitname = name, this.Manualnode = name2,
								this.Manualmark = name3, this.Manualvar = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mfltpc {
		width: 53vw;
		height: 78vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 3.5vw;
			font-size: 0.9vw;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 0.9vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 1.5vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 1.2vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 53vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 50vw;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			cursor: pointer;
		}

		.row3 {
			height: 6vw;
			margin-top: 2vh;
		}

		.jg1 {
			margin-left: 6.3vw;
			margin-top: -1.8vh;
		}

		.row3-jg1 {
			margin-left: 6.3vw;
			margin-top: -6.2vh;
		}

		.row3-jg2 {
			margin-left: 23.3vw;
			margin-top: 2.6vh;
		}

		.row3-jg3 {
			margin-top: 2.6vh;
		}

		.main {
			font-size: 0.9vw;
			color: #8aeaff;
		}

		.row1 {
			margin-top: 2vh;
			height: 7vw;
		}

		.row-span4 {
			width: 14vw;
			height: 13vh;
			border: 1px saddlebrown solid;
		}

		.color1 {
			color: #8aeaff;
		}

		.color2 {
			color: #00ffb4;
		}

		.row1-span1 {
			width: 10vw;
			height: 8vh;
			margin-left: 2vh;
			margin-top: 8vh;
			// border:1px solid saddlebrown;
		}

		.widthwd1 {
			width: 6vw;
			// text-align: left;
			padding-left: 1vw;
		}

		.widthwd3 {
			width: 7vw;
			// text-align: left;
			padding-left: 1vw;
		}

		.widthwd {
			cursor: pointer;
			position: relative;
			z-index: 100;
		}

		.widthwd2 {
			width: 3vw;
			height: 2vh;
			text-align: left;
			cursor: pointer;
		}

		.widthwd5 {
			width: 4vw;
			// text-align: left;
			margin-left: 1vw;

		}

		.widthwd6 {
			width: 5vw;
			// text-align: left;
			// margin-left:1vw;
		}

		.widthwd4 {
			width: 3vw;
			height: 3.8vh;
			line-height: 3vh;
			text-align: center;
			cursor: pointer;
		}

		.row1-span2-jg {
			margin-top: 3vh;
		}

		.maincenter-jg {
			margin-top: 10vh;
			margin-left: 5vw;
			width: 8vw;
			height: 3vh;
			background-color: #0f435d;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			line-height: 3vh;
			z-index: 2;
		}

		.maincenter-jg1 {
			margin-top: 3.5vh;
			margin-left: 2vw;
			width: 10vw;
			height: 3vh;
			background-color: #001b2860;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			line-height: 3vh;
			z-index: 2;
		}

		.row3-span2-jg {
			margin-top: 1vh;
		}

		.row1-span2-jg1 {
			margin-top: 1vh;
		}

		.row1-span2-jg2 {
			margin-top: 3vh;
		}

		.row1-span2-col1 {
			width: 10vw;
			height: 3vh;
			background-color: #001b2860;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			line-height: 3vh;
		}

		.row1-span14 {
			width: 2vw;
			margin-top: 3vh;
			margin-left: -3.5vw;
		}

		.widthd {
			position: relative;
			z-index: 100;
		}

		.row1-span14-col1 {
			width: 1vw;
			margin-top: 8vh;
			margin-left: 1vw;
		}

		.span3-jgs {
			margin-top: 4vh;
		}

		.row1-span3-col1 {
			width: 11vw;
			height: 3vh;
			background-color: #001b2860;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			line-height: 3vh;
		}

		.span3-jg {
			margin-top: 1vh;
		}

		.row1-span4-col1 {
			width: 15vw;
			height: 16vh;
			background-color: #001b2860;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			margin-top: 5vh;
		}

		.row1-span4-col2 {
			width: 8vw;
			height: 7vh;
			background-color: #001b2860;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			margin-top: 9vh;
			margin-left: 0.5vw;
		}

		.left-bottom {
			// border: solid 1px #0dc779;
			height: 32vh;
			width: 23.5vw;
		}

		.row1-span2-col2 {
			width: 29vw;
			height: 25vh;
			margin-top: 3vh;
			background-image: url("~@/assets/images/MFL_yfj.png");
			background-size: 100% 100%;
			z-index: 1;
			margin-left: 1vw;
		}

		.ylbutton {
			width: 6vh;
			height: 3vh;
			line-height: 3vh;
			background-color: #22fff7;
			border-radius: 0vh;
			color: #000;
			margin-top: 0.5vh;
			margin-left: 1vw;
			text-align: center;
		}

		.ylbutton-jg {
			margin-top: 0.6vh;
		}
	}
</style>
