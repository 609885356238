<template>
	<div class="mflzrqt drag" id="ZQTC" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>{{ projectData.project }}_ZRQT_C</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">再热汽温优化控制模型</div>
		<!-- 页面划分为2个部分。左边和右边。左边包含上下两部分，右边也包含上下两部分 -->
		<div class="main">
			<div class="fl leftmain">
				<div class="row1">
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_A_PV</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_A_PV', 'IOG', 'ZRQT_A_PV_BM','甲再热汽温过程值')"
							@dblclick="Cclick(infoList.IOG.ZRQT_A_PV,'ZRQT_A_PV', 'IOG', 'ZRQT_A_PV_BM')">
							{{infoList.IOG.ZRQT_A_PV}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_A_TC</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.MKG.ZRQT_A_TC,
                    'ZRQT_A_TC',
                     'ZRQT_A_TC',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ZRQT_A_TC}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_A_PVL</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_A_PVL', 'MKG', 'ZRQT_A_PVL_BM','再热汽A温度过程滤波值')"
							@dblclick="Cclick(infoList.MKG.ZRQT_A_PVL,'ZRQT_A_PVL', 'MKG', 'ZRQT_A_PVL_BM')">
							{{infoList.MKG.ZRQT_A_PVL}}</div>
					</div>
				</div>
				<div class="row2">
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_B_PV</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_B_PV', 'IOG', 'ZRQT_B_PV_BM','乙再热汽温过程值')"
							@dblclick="Cclick(infoList.IOG.ZRQT_B_PV,'ZRQT_B_PV', 'IOG', 'ZRQT_B_PV_BM')">
							{{infoList.IOG.ZRQT_B_PV}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_B_TC</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.MKG.ZRQT_B_TC,
                    'ZRQT_B_TC',
                     'ZRQT_B_TC',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ZRQT_B_TC}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_B_PVL</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_B_PVL', 'MKG', 'ZRQT_B_PVL_BM','再热汽B温度过程滤波值')"
							@dblclick="Cclick(infoList.MKG.ZRQT_B_PVL,'ZRQT_B_PVL', 'MKG', 'ZRQT_B_PVL_BM')">
							{{infoList.MKG.ZRQT_B_PVL}}</div>
					</div>
				</div>
				<div class="row3">
					<div class="fl">
						<div class="fl widthd1">ZRQT_SPB</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_SPB2', 'MKG', 'ZRQT_SPB_BM','再热器蒸汽流量SP')"
							@dblclick="Cclick(infoList.MKG.ZRQT_SPB2,'ZRQT_SPB2', 'MKG', 'ZRQT_SPB_BM')">
							{{infoList.MKG.ZRQT_SPB2}}</div>
					</div>
					<div class="fl bottonx" :style="{
            background: !infoList.RQM__p__ZRQ_RSF1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.RQM__p__ZRQ_RSF1
              ? toDetail(
                  3,
                  'ZRQ_RSF1',
                  'RQM__p__ZRQ_RSF1',
                  '',
                  '再热器流量补补偿'
                )
              : ''
          ">R</div>
				</div>
			</div>
			<div class="centermain fl">
				<div class="bottonx centerjg1" :style="{
            background: !infoList.XK__p__ZQT_XK9.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK9
              ? toDetail(
                  1,
                  'ZQT_XK9',
                  'XK__p__ZQT_XK9',
                  '',
                  '再热汽烟气挡板'
                )
              : ''
          ">X</div>
				<div style="width:20vw;height:13.5vh">
					<div class="row1 centerjg2 fl">
						<div class="row1-spon">
							<div class="fl widthd">ZRQT_A_SP</div>
							<div class="fl valuex" @click="toCompon(2, 'ZRQT_A_SP', 'IOG', 'ZRQT_A_SP_BM','甲再热汽温设定值')"
								@dblclick="Cclick(infoList.IOG.ZRQT_A_SP,'ZRQT_A_SP', 'IOG', 'ZRQT_A_SP_BM')">
								{{infoList.IOG.ZRQT_A_SP}}</div>
						</div>
						<div class="row1-spon">
							<div class="fl widthd">ZRQT_A_SPH</div>
							<div class="fl valuex down" @click="
                  toIpt(
                    infoList.MKG.ZRQT_A_SP_HL,
                    'ZRQT_A_SPH',
                     'ZRQT_A_SP_HL',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ZRQT_A_SP_HL}}</div>
						</div>
						<div class="row1-spon">
							<div class="fl widthd">ZRQT_A_SPL</div>
							<div class="fl valuex down" @click="
                  toIpt(
                    infoList.MKG.ZRQT_A_SP_LL,
                    'ZRQT_A_SPL',
                     'ZRQT_A_SP_LL',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ZRQT_A_SP_LL}}</div>
						</div>
					</div>
					<div class="fl bottonx centerjg5 " :style="{
              background:
              infoList.RQM__p__ZQT_MAN5.RM== 1 || infoList.RQM__p__ZQT_MAN6.RM== 1 || infoList.RQM__p__ZQT_MAN3.RM == 1 || infoList.RQM__p__ZQT_MAN4.RM == 1? '#2AFC30' : 'red'
              }" @click="toCompon(0,'MFL_MANZR',2)">A</div>
				</div>
				<div class="bottonx centerjg3" :style="{
            background: !infoList.XK__p__ZQT_XK10.RM ? '#2AFC30' : 'red',
          }" @click="
            infoList.XK__p__ZQT_XK10
              ? toDetail(
                  1,
                  'ZQT_XK10',
                  'XK__p__ZQT_XK10',
                  '',
                  '再热汽流量控制'
                )
              : ''
          ">X</div>
				<div class="row1 centerjg4">
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_B_SP</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_B_SP', 'IOG', 'ZRQT_B_SP_BM','乙再热汽温设定值')"
							@dblclick="Cclick(infoList.IOG.ZRQT_B_SP,'ZRQT_B_SP', 'IOG', 'ZRQT_B_SP_BM')">
							{{infoList.IOG.ZRQT_B_SP}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_B_SPH</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.MKG.ZRQT_B_SP_HL,
                    'ZRQT_B_SPH',
                     'ZRQT_B_SP_HL',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ZRQT_B_SP_HL}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd">ZRQT_B_SPL</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.MKG.ZRQT_A_SP_LL,
                    'ZRQT_B_SPL',
                     'ZRQT_A_SP_LL',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ZRQT_A_SP_LL}}</div>
					</div>
				</div>
			</div>
			<div class="fl rightmain">
				<div class="row11">
					<div class="row1-spon">
						<div class="fl widthd2">ZRQT_MAN5</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_A_PV', 'IOG', 'ZRQT_A_PV_BM','甲再热汽温过程值')"
							@dblclick="Cclick(infoList.IOG.ZRQT_A_PV,'ZRQT_A_PV', 'IOG', 'ZRQT_A_PV_BM')">
							{{infoList.IOG.ZRQT_A_PV}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd2">ZRQT_MVA_HL</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN5.OutT,
                    'ZRQT_MVA_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN5',
                   
                  )
                ">
							{{ infoList.RQM__p__ZQT_MAN5.OutT }}
						</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd2">ZRQT_MVA_LL</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN5.OutB,
                    'ZRQT_MVA_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN5',
                   
                  )
                ">
							{{ infoList.RQM__p__ZQT_MAN5.OutB }}
						</div>
					</div>
				</div>
				<div class="row21">
					<div class="row1-spon">
						<div class="fl widthd2">ZRQT_MAN6</div>
						<div class="fl valuex" @click="toCompon(2, 'ZRQT_B_MV', 'IOG', 'ZRQT_B_MV_BM','乙再热汽温XK输出')"
							@dblclick="Cclick(infoList.IOG.ZRQT_B_MV,'ZRQT_B_MV', 'IOG', 'ZRQT_B_MV_BM')">
							{{infoList.IOG.ZRQT_B_MV}}</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd2">ZRQT_MVB_HL</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN6.OutT,
                    'ZRQT_MVB_HL',
                     'OutT',
                    'RQM__p__ZQT_MAN6',
                   
                  )
                ">
							{{ infoList.RQM__p__ZQT_MAN6.OutT }}
						</div>
					</div>
					<div class="row1-spon">
						<div class="fl widthd2">ZRQT_MVB_LL</div>
						<div class="fl valuex down" @click="
                  toIpt(
                    infoList.RQM__p__ZQT_MAN6.OutB,
                    'ZRQT_MVB_LL',
                     'OutB',
                    'RQM__p__ZQT_MAN6',
                   
                  )
                ">
							{{ infoList.RQM__p__ZQT_MAN6.OutB }}
						</div>
					</div>
				</div>

			</div>
			<div>

			</div>


		</div>

		<div></div>

		<mflmanyManual v-if="mflmanyManual" :titname="Manualtitname" :numM="Manualnode" :mark="Manualmark"
			:manvar="Manualvar" @sendStatus="isClose" :infoList="infoList"></mflmanyManual>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>


	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import mflmanyManual from "@/components/MflmanyManual.vue"; //多手操器

	export default {
		name: "MFL_ZQTC",
		components: {
			mflmanyManual,
			Historical,
			inputVal,
		},
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				isMshow: false,

				flag: 0,
				Manualmark: '',
				Manualvar: '',
				mflmanyManual: false,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				MfZQFOZXCS1: "",
				MfZQFOZXCS2: "",
				MfZQFOZXCS3: "",
				MfZQFOZXCS4: "",
				MfZQFOZX: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
				spotArr: [],
				authInfo: [],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
		},
		computed: {},
		mounted() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'mfkfirstcontol'
							break
						case 3:
							pathname = 'mfRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "iszqwdshow", false);
			},
			isClose(val, val1) {
				console.log(val)
				console.log(val1)
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'MfZQFOZX':
						return this.MfZQFOZX = val1
					case 'mflmanyManual':
						return this.mflmanyManual = val1
				}
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				switch (key) {
					case 0:
						return this.mflmanyManual = true, this.Manualtitname = name, this.Manualnode = name2, this
							.Manualmark = name3, this.Manualvar = titname
					case 1:
						return (
							(this.isFshow = true),
							(this.Firstcontrolname = name),
							(this.Firstcontrolnode = name2),
							(this.Firstcontroltitname = titname)
						);
					case 2:
						return ((this.isHshow = true), (this.chName = name4));
					case 13:
						return this.MfZQFOZX = true, this.MfZQFOZXCS1 = name, this.MfZQFOZXCS2 = name2, this.MfZQFOZXCS3 =
							name3, this.MfZQFOZXCS4 = titname
				}
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mflzrqt {
		width: 53vw;
		height: 67vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 3.5vw;
			font-size: 0.9vw;
		}

		.left-span1 {
			width: 10vw;
			height: 30vh;
			// border: 1px saddlebrown solid;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 0.9vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 1.5vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 1.2vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 53vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 50vw;
		}

		.main {
			font-size: 1vw;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			cursor: pointer;
			position: relative;
			z-index: 2;
		}

		.leftmain {
			margin: 4vh 0 0 3vw;
			width: 10vw;
			height: 45vh;
			//   border: 1px solid rgb(217, 243, 145);
		}

		.centermain {
			margin: 8vh 0 0 0vw;
			width: 27vw;
			height: 34vh;
			background-image: url("~@/assets/images/MFL-ZRQT.png");
			background-size: 100% 100%;
		}

		.row1 {
			width: 10vw;
			height: 10vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
		}

		.row11 {
			width: 12vw;
			height: 10vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
		}

		.widthd {
			width: 6vw;
			height: 3vh;
			line-height: 3vh;
			color: #8aeaff;
			text-align: center;

		}

		.widthd1 {
			width: 4.5vw;
			height: 3vh;
			line-height: 3vh;
			color: #8aeaff;
			text-align: center;

		}

		.widthd2 {
			width: 7vw;
			height: 3vh;
			line-height: 3vh;
			color: #8aeaff;
			text-align: center;

		}

		.valuex {
			width: 4vw;
			height: 3vh;
			line-height: 3vh;
			color: #8aeaff;
			text-align: center;
		}

		.down {
			color: #00ffb4;
		}

		.row2 {
			margin: 15vh 0 0 0;
			width: 10vw;
			height: 10vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
		}

		.row21 {
			margin: 15vh 0 0 0;
			width: 12vw;
			height: 10vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
		}

		.row3 {
			margin: 1vh 0 0 0;
		}

		.centerjg1 {
			margin: -1vh 0 0 10.8vw;
			position: relative;
			z-index: 100;
		}

		.centerjg2 {
			margin: 3.5vh 0 0 6vw;
			position: relative;
			z-index: 100;
		}

		.centerjg4 {
			margin: 6vh 0 0 6vw;
			position: relative;
			z-index: 100;
		}

		.centerjg3 {
			margin: 8.5vh 0 0 10.8vw;
			position: relative;
			z-index: 100;
		}

		.centerjg5 {
			margin: -4vh 0 0 22.8vw;

		}

		.rightmain {
			margin: 4vh 0 0 0vw;
			width: 10vw;
			height: 45vh;
			//   border: 1px solid rgb(217, 243, 145);
		}


	}
</style>
