var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mfzqfozx drag",attrs:{"id":"mfzqfozx"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_"+_vm._s(_vm.MfZQFOZXCS1)+"_ZX")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v(_vm._s(_vm.MfZQFOZXCS2))]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 序号")]),(_vm.MfZQFOZXCS1!='ZQFS')?_c('div',{staticClass:"row1-span2 color1 fl"},[_vm._v(" "+_vm._s(_vm.MfZQFOZXCS1)+"_IN")]):_vm._e(),(_vm.MfZQFOZXCS1!='ZQFS')?_c('div',{staticClass:"row1-span3 color1 fl"},[_vm._v(" "+_vm._s(_vm.MfZQFOZXCS1)+"_AV")]):_vm._e(),(_vm.MfZQFOZXCS1=='ZQFS')?_c('div',{staticClass:"row1-span2 color1 fl"},[_vm._v(" "+_vm._s(_vm.MfZQFOZXCS3)+"_IN")]):_vm._e(),(_vm.MfZQFOZXCS1=='ZQFS')?_c('div',{staticClass:"row1-span3 color1 fl"},[_vm._v(" "+_vm._s(_vm.MfZQFOZXCS4)+"_AV")]):_vm._e()]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 1")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__1__rr__,
                    _vm.MfZQFOZXCS1+'_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__1__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__1__rr__,
                    _vm.MfZQFOZXCS1+'_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__1__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 2")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__2__rr__,
                    _vm.MfZQFOZXCS1+'_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__2__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__2__rr__,
                    _vm.MfZQFOZXCS1+'_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__2__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 3")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__3__rr__,
                    _vm.MfZQFOZXCS1+'_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__3__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__3__rr__,
                    _vm.MfZQFOZXCS1+'_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__3__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 4")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__4__rr__,
                    _vm.MfZQFOZXCS1+'_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__4__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__4__rr__,
                    _vm.MfZQFOZXCS1+'_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__4__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 5")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__5__rr__,
                    _vm.MfZQFOZXCS1+'_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__5__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__5__rr__,
                    _vm.MfZQFOZXCS1+'_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__5__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 6")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__6__rr__,
                    _vm.MfZQFOZXCS1+'_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__6__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__6__rr__,
                    _vm.MfZQFOZXCS1+'_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__6__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 7")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__7__rr__,
                    _vm.MfZQFOZXCS1+'_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__7__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__7__rr__,
                    _vm.MfZQFOZXCS1+'_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__7__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 8")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__8__rr__,
                    _vm.MfZQFOZXCS1+'_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__8__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__8__rr__,
                    _vm.MfZQFOZXCS1+'_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__8__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 9")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__9__rr__,
                    _vm.MfZQFOZXCS1+'_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__9__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__9__rr__,
                    _vm.MfZQFOZXCS1+'_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__9__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 10")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__10__rr__,
                    _vm.MfZQFOZXCS1+'_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__10__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__10__rr__,
                    _vm.MfZQFOZXCS1+'_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__10__rr__)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-span1 fl"},[_vm._v(" 11")]),_c('div',{staticClass:"row1-span2 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__11__rr__,
                    _vm.MfZQFOZXCS1+'_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].X__ll__11__rr__)+" ")]),_c('div',{staticClass:"row1-span3 fl",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__11__rr__,
                    _vm.MfZQFOZXCS1+'_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__'+ _vm.MfZQFOZXCS1 
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList['MKG__p__'+_vm.MfZQFOZXCS1].Y__ll__11__rr__)+" ")])]),_c('div',{staticClass:"row3 "},[_c('div',{staticClass:"fl ",staticStyle:{"margin-left":"8vw"}},[_vm._v("IN = ")]),_c('div',{staticClass:"fl ",on:{"click":function($event){return _vm.toCompon(2,'GML_SP', 'MKG', 'GML_SP_BM','总给煤指令')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.GML_SP,'GML_SP', 'MKG', 'GML_SP_BM')}}},[_vm._v(_vm._s(_vm.infoList.MKG.GML_SP))]),_c('div',{staticClass:"fl ",staticStyle:{"margin-left":"1vw"}},[_vm._v("AV = ")]),(_vm.MfZQFOZXCS1!='ZQFS')?_c('div',{staticClass:"fl ",on:{"click":function($event){return _vm.toCompon(2,_vm.MfZQFOZXCS1 +'_AV', 'MKG', _vm.MfZQFOZXCS1 +'_AV_BM','屏过出口设定值输出')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG[_vm.MfZQFOZXCS1 +'_AV'],_vm.MfZQFOZXCS1 +'_AV', 'MKG', _vm.MfZQFOZXCS1 +'_AV_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG[_vm.MfZQFOZXCS1 +'_AV']))]):_vm._e(),(_vm.MfZQFOZXCS1=='ZQFS')?_c('div',{staticClass:"fl ",on:{"click":function($event){return _vm.toCompon(2,'SFF_MIN', 'MKG', 'SFF_M_IN_BM')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.MKG.SFF_MIN,'SFF_MIN', 'MKG','SFF_M_IN_BM')}}},[_vm._v(_vm._s(_vm.infoList.MKG.SFF_MIN)+" ")]):_vm._e()])]),(_vm.MfZQFOZXCS1=='ZQFO')?_c('div',{staticClass:"main-bottom",staticStyle:{"margin-top":"1vh"}},[_c('div',{staticClass:"fl",staticStyle:{"margin-left":"9vw"}},[_vm._v(" MFL_A_SPB = ")]),_c('div',{staticClass:"fl color1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MKG.YQO_SP_B2,
                    'YQO_SP_B2',
                     'YQO_SP_B2',
                    'MKG'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MKG.YQO_SP_B2)+" ")])]):_vm._e(),(_vm.MfZQFOZXCS1=='ZQFO')?_c('div',{staticClass:"main-bottom"},[_c('div',{staticClass:"fl",staticStyle:{"margin-left":"9vw"}},[_vm._v(" MYP_A_SP = _AV + SPB = ")]),_c('div',{staticClass:"fl",on:{"click":function($event){return _vm.toCompon(2,'YQO_SP', 'IOG', 'YQO_SP_BM','烟气氧量设定值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.IOG.YQO_SP,'YQO_SP', 'IOG', 'YQO_SP_BM')}}},[_vm._v(" "+_vm._s(_vm.infoList.IOG.YQO_SP))])]):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }